import React, {useLayoutEffect} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {RiArrowRightLine} from 'react-icons/ri'
import Button from "../../components/button";
import Background from "../../components/background";
import ErrorBox from '../../components/errorbox';
import {storeRegistrationData} from "../../redux/actions/register";


const Hexagon = styled.img`
  width: 50px;
`;

const Heading = styled.div`
  font-size: 1.1em;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
  width: 70%;
  color: ${props => props.theme.text.main || 'blue'};
`;

const InstallerNote = styled.div`
  border: 2px rgb(205,204, 204) solid;
  width: 100%;
  border-radius: 15px;
  background: rgb(243, 146, 0);
  padding: 8px 15px;
  font-size: 0.9em;
  line-height: 18px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
    //border: solid 1px blue;
    //min-width: 320px !important;
    padding: 20px 6px 20px 25px;
    flex: 1;
`;

const RegisterInstructionsScreen = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const submit = (e) => {
        e.preventDefault();

        props.history.push('/quizzer');
    };

    return (
        <Background>
            {props.register.error ? <ErrorBox>{props.register.error}</ErrorBox> : null}
            <div style={{width: '100%', marginTop: 20, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <Heading>Have you installed Intelizzz in to any vehicle previously?</Heading>
            </div>
            <div style={{width: '60%', display: 'flex', flexDirection: 'row', marginTop: 20, justifyContent: 'space-around', alignItems: 'center'}}>
                <Button style={{maxWidth: 90, fontSize: '1.1em', border: '2px rgb(205,204, 204) solid'}} status="primaryTwo" textStatus="light" >YES</Button>
                <Button style={{maxWidth: 90, fontSize: '1.1em', border: '2px rgb(205,204, 204) solid'}} status="primaryOne" textStatus="light" >NO</Button>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingLeft: 15,
                paddingRight: 15
            }}>

                <div style={{margin: '20px 11px'}}>
                    <InstallerNote>
                        <p>To generate a unique <span style={{fontWeight: 'bold'}}>installer ID</span> you will need to answer a few questions regarding the installation
                            of Intelizzz to ensure you know how to install the device correctly to ensure a good connection.</p>
                        <p><span style={{fontWeight: 'bold'}}>IMPORTANT NOTE:</span><br/>
                        Intelizzz is a wireless device that requires a good GPS Signal to work effectively.</p>
                    </InstallerNote>
                </div>
            </div>

            <Footer>
                <div>Continue</div>
                <Button status="invisible" style={{width: 55, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <RiArrowRightLine style={{position: 'absolute', color: 'rgb(255,255,255)'}} size="30"
                        // onClick={navTo('device')}
                                      onClick={submit}
                                      color="red"/>
                    <Hexagon src='/assets/images/shapes/hexagonal.svg' />
                </Button>
            </Footer>
        </Background>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {register: state.register};
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveData: (payload) => dispatch(storeRegistrationData(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterInstructionsScreen);
