import React from 'react';
import {motion} from 'framer-motion';
import {RiKeyboardLine} from 'react-icons/ri';
import styled from 'styled-components';
import Tile from '../tile';

const ContainerDiv = styled(motion.div)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  ${props => props.expanded ? 'minHeight: 193px' : null};
  width: 100%;
  padding: 5px 5px 0 5px;
  // background: ${props => !props.expanded ? 'rgb(255,150,150,0.2)' : null};
  // opacity: ${props => !props.expanded ? 0.7 : null};
`;

const TopRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  padding: 8px 8px 0 8px;
`;

const SubTitle = styled.span`
  font-size: 0.4em;
  color: white; 
  font-weight: bolder;
  text-align: left;
`;

const SearchIcon = styled.img`
  width: 16px;
`;

const CameraRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  //padding: 8px 8px 0 8px;
`;

const CameraIcon = styled.img`
  width: 28px;
`;

const ScanTypeRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  //padding: 8px 8px 0 8px;
`;

const ScanTypeIcon = styled.img`
  width: ${props => props.scanType === 'barcode' ? '150px' : '85px'};
  padding: 15px 0 15px 0;
`;

const ScanResultRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  align-items: center;
  //padding: 8px 8px 0 8px;
`;

const ScanResultContainer = styled.div`
  border: 1px solid rgb(171, 171, 171);
  min-height: 30px;
  border-radius: 5px;
  margin: 0 5px 10px 5px;
  padding: 4px;
  flex: 1;
`;

const ScanResult = styled.span`
  color: white;
  text-align: center;
  font-size: 1em;
  font-weight: 300;
`;

const RegPlate = styled.div`
  border: 2px solid rgb(0, 0, 0);
  margin: 5px 0 15px 0;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-family: UKNumberPlate;
  background: #ffcf5d;
  color: black;
`;

const RegPlateIcon = () => {
    return (
        <RegPlate>CAR REG</RegPlate>
    )
};

const InstallTile = ({status, textStatus, subtitle, onClick, expanded, scanType='barcode', scanResult='TAP TO SCAN', entryType='scan', style}) => {
    const _expanded = expanded === null || expanded === undefined ? true : expanded;
    return (<Tile
                status={status}
                textStatus={textStatus}
                onClick={_expanded ? onClick : () => null}
                style={{...style}}
            >
                <ContainerDiv expanded={_expanded}>
                    <TopRow>
                        <SubTitle>{_expanded || scanResult ? subtitle : ''}</SubTitle>
                        <SearchIcon src={_expanded ? '/assets/images/shapes/search-icon.svg' : null}/>
                    </TopRow>
                    {_expanded ?
                    <CameraRow>
                        {entryType === 'scan' ? <CameraIcon src='/assets/images/shapes/camera-icon.svg'/> : <RiKeyboardLine color='white' size={45} />}
                    </CameraRow> : null}
                    {_expanded ?<ScanTypeRow>
                        {entryType === 'scan' ? <ScanTypeIcon src={`/assets/images/shapes/${scanType}-icon.svg`} scanType={scanType} /> : <RegPlateIcon />}
                    </ScanTypeRow> : null}
                    <ScanResultRow>
                        <ScanResultContainer>
                            {!_expanded ? <ScanResult>
                                {scanResult ? scanResult : subtitle}
                            </ScanResult> : null}
                            {expanded ? <ScanResult>
                                TAP TO {entryType === 'scan' ? 'SCAN' : 'ENTER'}
                            </ScanResult> : null}
                        </ScanResultContainer>
                    </ScanResultRow>
                </ContainerDiv>
    </Tile>);
};

export default InstallTile;
