import React, {useEffect, useState, useLayoutEffect} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {BarcodeFormat, DecodeHintType, BrowserMultiFormatReader} from '@zxing/library';
import {RiErrorWarningLine, RiCloseLine} from 'react-icons/ri';

import ScanditBarcodeScanner from 'scandit-sdk-react';
import {
    Barcode,
    BarcodePicker,
    Camera,
    CameraAccess,
    CameraSettings,
    ScanSettings,
    SingleImageModeSettings,
} from "scandit-sdk";
import PropTypes from "prop-types";
import DialogBox from "../../components/dialogbox";
import Config from '../../config';

// LOCAL
// const licenseKey = "Aa2g6xLeMtjDJWNhbUU2ir8aLxUjNtOuujKVOnR0MGvCSoT+yW/i6OtUzrMAfyd9qUaW9HwiTcMabXEQ2kE1YdVS+R8jYaQ81mRPFTxuVvCMITwDDkLOrOMBQ1V2L2YFW1Cz245ZPvev5CiS5iaGAxeK8GJ6O/ml+sxohpOmFe7LZ1cjSVwYcYrUItDpKrA8qbdR/6K5cXs7Exmzox2RTegViw83UTDMDoLHbZwda+OY8l4PBiXf2PUvqEeDt8klCqejbzmu50q7D/S2Dqi9qHycM6Rf9zvdO7DTTQOK2OuX0APj7tIZUu8zEb733PbNvhPSn9OBFTA13uPqAWcuI4OxO3VVJV6HD4G9jujZPe+z6qbmiodVyUh9rlMZf8rw1P+hlv62ywMOT5YBKxIyWsrHAtpb24K1dbEWAYSCS0H2ACzgL8qUo6aalWHcY9FFavbWLN1h5Sw7TZ6Zhg8nmMxfREzNnahCvpLbWDQY+a+YuCrLXFZ+ejIULIRRhoErTYpEeOp+nVe4HUZjTFT1/WOLXzmYf2Mju15OymvoClk56R9Vv8OmrJJazqWiwM1b1Eowa3wLhiTK02/bNYN6MURjvcs9ILiNB/xLJXjibsbiopggkunOM6uB5d0lcQFVQ7M/9uqml894R/gfcxuy4Tp7LU5anJ1uvoERWzAD+s4ezESc+gP28acSIjueIZnpDz7dNakM/B6DD6hBbY4aAxl/XqJdemFk0X826Ow62ieyjt6Zjkf+MtQIBMcvraa8Z4uf0OEwuqpoSuNV6Pvb3j3ABVW0a1ds0qllAQQwCA==";
// INTELIZZZ
const licenseKey = Config.camerakey;

const Video = styled.video`
    display: block;
    height: 100vh;
    position: relative;
    width: 100%;
    object-fit: cover;
`;

const ScannerContainer = styled(motion.div)`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    width: 100%;
`;

const Scanner = styled(motion.div)`
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(42, 42, 24);
`;

const VideoContainer = styled(motion.div)`
    background-color: #000;
    height: 100vh;
    min-height: 1px;
    min-width: 1px;
    position: relative;
    width: 100%;
`;

const CloseBox = styled(motion.div)`
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: 15px;
  min-height: 30px;
  max-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgb(213, 2, 1);
`;

const ErrorIcon = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  min-height: 50px;
  max-width: 60px;
  min-width: 60px;
  background-color: rgb(160, 0, 0);
  display: flex;
    justify-content: center;
  align-items: center;
  color: white;
`;

const ErrorText = styled.div`
  font-size: 0.8em;
  min-width: 200px;
  padding: 0 10px;
    font-weight: 600;
      color: white;

`;

const ErrorBox = ({children}) => (
    <ErrorContainer><ErrorIcon><RiErrorWarningLine size="30"/></ErrorIcon><ErrorText>{children}</ErrorText></ErrorContainer>
);

const CameraView = ({dialog = (<div>??????</div>), result, error, close}) => {
    const [code, setCode] = useState(null);
    const [stopped, setStopped] = useState(false);
    const [readerRef, setReaderRef] = useState(undefined);
    const [camerasGot, setCamerasGot] = useState(false);
    const [startScanner, setStartScanner] = useState(false);
    const [internalError, setInternalError] = useState(null);

    const [scannerState, setScannerState] = useState({
        shouldShowScannerComponent: true,
        paused: false,
        // isCode128Enabled: true,
        accessCamera: true,
        cameras: [],
        cameraSettings: {
            resolutionPreference: CameraSettings.ResolutionPreference.HD,
        },
        enableCameraSwitcher: false,
        enablePinchToZoom: true,
        enableTapToFocus: true,
        enableTorchToggle: true,
        guiStyle: BarcodePicker.GuiStyle.NONE,
        // laserArea: { x: 0, y: 0, width: 1, height: 1 },
        playSoundOnScan: true,
        targetScanningFPS: 30,
        vibrateOnScan: true,
        videoFit: BarcodePicker.ObjectFit.COVER,
        visible: true,
        // viewfinderArea: { x: 0, y: 0, width: 1, height: 1 },
        zoom: 0,
        cameraType: Camera.Type.BACK,
        singleImageModeSettings: {
            desktop: {
                usageStrategy: SingleImageModeSettings.UsageStrategy.NEVER,
            },
            mobile: {
                usageStrategy: SingleImageModeSettings.UsageStrategy.NEVER,
            },
        },
    });

    useLayoutEffect(() => {
    });

    useState(() => {
        if(!camerasGot) {
            setCamerasGot(true);
            CameraAccess.getCameras().then((cameras) => setScannerState({ ...scannerState, cameras }));
            setScannerState({...scannerState, paused: false, accessCamera: true});
            setStartScanner(true);
        }

        if(startScanner) {
            setScannerState({...scannerState, paused: false, accessCamera: true});
            setStartScanner(false);
        }
    });

    const getScanSettings = () => {
        const scanSettings = new ScanSettings({
            enabledSymbologies: [
                // Barcode.Symbology.EAN8,
                // Barcode.Symbology.EAN13,
                // Barcode.Symbology.UPCA,
                // Barcode.Symbology.UPCE,
                Barcode.Symbology.CODE128,
                // Barcode.Symbology.CODE39,
                // Barcode.Symbology.CODE93,
                // Barcode.Symbology.INTERLEAVED_2_OF_5,
                Barcode.Symbology.QR,
                // Barcode.Symbology.AZTEC,
                // Barcode.Symbology.DATA_MATRIX,
                // Barcode.Symbology.DOTCODE,
                // Barcode.Symbology.MAXICODE,
                // Barcode.Symbology.MICRO_PDF417,
                // Barcode.Symbology.PDF417,
                Barcode.Symbology.MICRO_QR,
            ],
            codeDuplicateFilter: 1000,
        });

        return scanSettings;
    };

    const getScannerState = () => {
        if (!scannerState.shouldShowScannerComponent) {
            return "Not shown, will initialize";
        } else if (scannerState.shouldShowScannerComponent && !scannerState.scannerReady) {
            return "Initializing";
        } else if (scannerState.shouldShowScannerComponent && scannerState.scannerReady) {
            if (!scannerState.paused) {
                return "Ready & Scanning";
            } else {
                return "Ready & Paused";
            }
        }
    };

    // useEffect(() => {
    //     if (!readerRef) {
    //         const hints = new Map();
    //         const formats = [
    //             BarcodeFormat.QR_CODE,
    //             BarcodeFormat.CODE_39,
    //             BarcodeFormat.DATA_MATRIX,
    //             BarcodeFormat.CODE_93,
    //             BarcodeFormat.CODE_128,
    //             BarcodeFormat.EAN_8,
    //             BarcodeFormat.EAN_13,
    //             BarcodeFormat.ITF,
    //             BarcodeFormat.UPC_EAN_EXTENSION,
    //             BarcodeFormat.UPC_E,
    //             BarcodeFormat.UPC_A,
    //             BarcodeFormat.RSS_EXPANDED,
    //             BarcodeFormat.RSS_14,
    //             BarcodeFormat.PDF_417,
    //             BarcodeFormat.MAXICODE,
    //             BarcodeFormat.CODABAR,
    //             BarcodeFormat.AZTEC,
    //         ];
    //
    //         hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    //         // hints.set(DecodeHintType.TRY_HARDER, true);
    //
    //         const reader = new BrowserMultiFormatReader(hints);
    //
    //         setReaderRef(reader);
    //
    //         reader.decodeFromConstraints({
    //             video: {
    //                 aspectRatio: 1,
    //                 facingMode: 'environment',
    //                 frameRate: {ideal: 30, max: 60},
    //                 width: 1280,
    //                 height: 720,
    //             },
    //             audio: false
    //         }, 'video', (result) => {
    //             if (result) setCode(result.text);
    //         });
    //     }
    // }, [stopped, setStopped, readerRef]);
    //
    // useLayoutEffect(() => {
    //     return () => {
    //         if (readerRef) {
    //             readerRef.reset();
    //             setReaderRef(undefined);
    //         }
    //     }
    // }, [readerRef]);

    const zxing = (
        <Scanner>
            <ScannerContainer>
                <VideoContainer>
                    <Video id="video" autoplay="autoplay" playsinline="true" muted="muted"
                           data-object-fit="contain"/>

                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        flexDirection: 'column',
                        marginTop: 100
                    }}>
                        <svg width="200" viewBox="0 0 98.88 99.74" style={{marginBottom: 55}}>
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1" fill="white">
                                    <path className="cls-1"
                                          d="M84,2.35h7a5.59,5.59,0,0,1,5.58,5.58v7.71h2.35V7.93A7.94,7.94,0,0,0,91,0H84Z"/>
                                    <path className="cls-1"
                                          d="M2.35,15.64V7.93A5.58,5.58,0,0,1,7.93,2.35h6.94V0H7.93A7.94,7.94,0,0,0,0,7.93v7.71Z"/>
                                    <path className="cls-1"
                                          d="M96.53,85.63v6.19A5.6,5.6,0,0,1,91,97.4H84v2.34h7a7.93,7.93,0,0,0,7.93-7.92V85.63Z"/>
                                    <path className="cls-1"
                                          d="M14.87,97.4H7.93a5.59,5.59,0,0,1-5.58-5.58V85.63H0v6.19a7.93,7.93,0,0,0,7.93,7.92h6.94Z"/>
                                </g>
                            </g>
                        </svg>
                        <p style={{
                            color: 'white',
                            border: 'solid 1px rgb(155, 155, 155)',
                            minWidth: 320,
                            textAlign: 'center',
                            borderRadius: 5,
                            fontWeight: 500,
                            fontSize: '1.5em',
                            padding: 5
                        }}>{code ? code : 'SCANNING...'}</p>

                        <motion.div
                            style={{paddingTop: 50}}
                            whileTap={{scale: 0.5}}
                            onClick={() => {
                                if(result) result(code);
                            }}>
                            <svg width="80" viewBox="0 0 47.89 47.89">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1" fill="#e30613">
                                        <circle cx="23.95" cy="23.95" r="23.8" fill="none" stroke="#e30613"
                                                strokeMiterlimit="10" strokeWidth="0.28px"/>
                                        <circle cx="23.95" cy="23.95" r="21.58" fill="#e30613"/>
                                    </g>
                                </g>
                            </svg>
                        </motion.div>
                    </div>

                </VideoContainer>
                {close ? <CloseBox onClick={close}><RiCloseLine size="35"/></CloseBox> : null}
                {error ? <ErrorBox>{error}</ErrorBox> : null}
            </ScannerContainer>
        </Scanner>
    );

    const scandit = (
        <Scanner>
            <ScannerContainer>
                <VideoContainer>
            <pre>{getScannerState()} : {JSON.stringify(scannerState.cameras)}</pre>
            {scannerState.shouldShowScannerComponent && (
            <ScanditBarcodeScanner
                // Library licensing & configuration options (see https://docs.scandit.com/stable/web/globals.html#configure)
                licenseKey={licenseKey}
                preloadBlurryRecognition={true}
                preloadEngine={true}
                engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build" // could also be a local folder, e.g. "build"
                // Picker events
                onReady={() => setScannerState({ ...scannerState, scannerReady: true })}
                // eslint-disable-next-line no-console
                onScan={(res) => {
                    // console.log(res);
                    if(res.barcodes && res.barcodes.length > 0 && res.barcodes[0].data) {
                        setCode(res.barcodes[0].data);
                    }
                }}
                // eslint-disable-next-line no-console
                onScanError={(err) => {
                    console.error(err);
                    setInternalError(err);
                }}

                // Picker options
                scanSettings={getScanSettings()}
                paused={scannerState.paused}
                /*️
                  ⚠️ Make sure to keep accessCamera and paused synchronized in a sensible way, as resuming scanning accesses
                  the camera, so your state might become outdated.
                  For example, set accessCamera to true whenever scanning is resumed.
                */
                accessCamera={scannerState.accessCamera}
                camera={scannerState.activeCamera}
                cameraSettings={scannerState.cameraSettings}
                enableCameraSwitcher={scannerState.enableCameraSwitcher}
                enablePinchToZoom={scannerState.enablePinchToZoom}
                enableTapToFocus={scannerState.enableTapToFocus}
                enableTorchToggle={scannerState.enableTorchToggle}
                guiStyle={scannerState.guiStyle}
                laserArea={scannerState.laserArea}
                playSoundOnScan={scannerState.playSoundOnScan}
                targetScanningFPS={scannerState.targetScanningFPS}
                vibrateOnScan={scannerState.vibrateOnScan}
                videoFit={scannerState.videoFit}
                visible={scannerState.visible}
                viewfinderArea={scannerState.viewfinderArea}
                zoom={scannerState.zoom}
                // only set on component creation, can not be changed afterwards
                cameraType={scannerState.cameraType}
                singleImageModeSettings={scannerState.singleImageModeSettings} // only set on component creation, can not be changed afterwards
            />
            )}

                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        flexDirection: 'column',
                        marginTop: 100
                    }}>
                        <svg width="200" viewBox="0 0 98.88 99.74" style={{marginBottom: 55}}>
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1" fill="white">
                                    <path className="cls-1"
                                          d="M84,2.35h7a5.59,5.59,0,0,1,5.58,5.58v7.71h2.35V7.93A7.94,7.94,0,0,0,91,0H84Z"/>
                                    <path className="cls-1"
                                          d="M2.35,15.64V7.93A5.58,5.58,0,0,1,7.93,2.35h6.94V0H7.93A7.94,7.94,0,0,0,0,7.93v7.71Z"/>
                                    <path className="cls-1"
                                          d="M96.53,85.63v6.19A5.6,5.6,0,0,1,91,97.4H84v2.34h7a7.93,7.93,0,0,0,7.93-7.92V85.63Z"/>
                                    <path className="cls-1"
                                          d="M14.87,97.4H7.93a5.59,5.59,0,0,1-5.58-5.58V85.63H0v6.19a7.93,7.93,0,0,0,7.93,7.92h6.94Z"/>
                                </g>
                            </g>
                        </svg>
                        <p style={{
                            color: 'white',
                            border: 'solid 1px rgb(155, 155, 155)',
                            minWidth: 320,
                            textAlign: 'center',
                            borderRadius: 5,
                            fontWeight: 500,
                            fontSize: '1.5em',
                            padding: 5
                        }}>{code ? code : 'SCANNING...'}</p>

                        <motion.div
                            style={{paddingTop: 50}}
                            whileTap={{scale: 0.5}}
                            onClick={() => {
                                if(result) result(code);
                            }}>
                            <svg width="80" viewBox="0 0 47.89 47.89">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1" fill="#e30613">
                                        <circle cx="23.95" cy="23.95" r="23.8" fill="none" stroke="#e30613"
                                                strokeMiterlimit="10" strokeWidth="0.28px"/>
                                        <circle cx="23.95" cy="23.95" r="21.58" fill="#e30613"/>
                                    </g>
                                </g>
                            </svg>
                        </motion.div>
                    </div>
                    {dialog}

                </VideoContainer>
                {close ? <CloseBox onClick={close}><RiCloseLine size="35"/></CloseBox> : null}
                {error ? <ErrorBox>{error}</ErrorBox> : null}
                {internalError ? <ErrorBox>{internalError}</ErrorBox> : null}
            </ScannerContainer>
        </Scanner>
    );

    return scandit;
};

export default CameraView;
