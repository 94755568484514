import React from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {RiCloseLine} from 'react-icons/ri';

const DialogBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: calc(100vh - calc(100vh - 100%));
  background: #000;
  opacity: 0.6;
`;

const _DialogBox = styled.div`
    top: ${props => props.top || '10px'};
    left: ${props => props.left || '10px'};
    //height: calc(100vh - ${props => props.height || '20px'});
    height: ${props => 
        props.height === 'auto' ? 'auto' : `calc(100vh - ${props.height || '20px'})`
    };
    width: calc(100vw - ${props => props.width || '20px'});
    background: #FFF;
    position: absolute;
    border-radius: 20px;
    -webkit-box-shadow: 0px 2px 0px 3px #AAAAAA; 
    box-shadow: 0px 1px 0px 1px #555;
    flex: 1;
    padding-bottom: 15px;
    z-index: 10000;
`;

const CloseBox = styled(motion.div)`
  position: relative;
  color: white;
  top: 0;
  left: 0;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
`;

const DialogBox = ({visible, close, children, top, left, width, height}, props) => {
    return (
        <>
            {visible ?
                <React.Fragment>
                    <DialogBackground onClick={close}/>
                    <_DialogBox top={top} left={left} width={width} height={height}>
                        {close ? <CloseBox onClick={close}><RiCloseLine size="35" color="black"/></CloseBox> : null}
                        {children}
                    </_DialogBox>
                </React.Fragment>
                : null}
        </>
    )
};

export default DialogBox;
