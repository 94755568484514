import styled from 'styled-components';
import {motion} from "framer-motion";

const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  ${props => window.outerHeight > window.innerHeight ? 'min-height: -webkit-fill-available' : null};
  //min-height: calc(100vh-10%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  //flex: 1;
  //border: solid 3px orange;
  
  content: "";
  background-color: #FFFFFF;
  background-image: url('/assets/images/backgrounds/background-main.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
`;

export default Background;
