import React from 'react';
import styled, { withTheme } from "styled-components";

const Logo = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

const PrimaryLogo = styled(Logo)`
    margin-top: 0;
`;

const GetLogo = ({theme, logoData}) => {
    return (
        <>
            <div style={{maxWidth: theme.logos.primary.width}}>
                {/*<PrimaryLogo src={(theme.logos.dealers && logoData && logoData.name && theme.logos.dealers[logoData.name.toLowerCase()]) || theme.logos.primary.image}/>*/}
                <PrimaryLogo src={(theme.logos.dealers && logoData && logoData.name && theme.logos.dealers[logoData.name.toLowerCase()])}/>
            </div>
        </>
    )
};

export default withTheme(GetLogo);
