import React from 'react';
import './App.css';
import Root from "../router";
import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../redux/reducers';

const loggerMiddleware = createLogger();
const middleware = applyMiddleware(thunkMiddleware, loggerMiddleware);

const store = createStore(rootReducer, middleware);

function App() {
  return (
    <Root store={store}/>
  );
}

export default App;
