import React from "react";
import styled from "styled-components";
import {RiErrorWarningLine, RiCloseLine} from 'react-icons/ri';

const ErrorContainer = styled.div`
  z-index: 1000;
  position: absolute;
  top: 15px;
  min-height: 30px;
  max-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgb(213, 2, 1);
`;

const ErrorIcon = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  min-height: 50px;
  max-width: 60px;
  min-width: 60px;
  background-color: rgb(160, 0, 0);
  display: flex;
    justify-content: center;
  align-items: center;
  color: white;
`;

const CloseIcon = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  min-height: 50px;
  max-width: 30px;
  min-width: 30px;
  //background-color: rgb(160, 0, 0);
  display: flex;
    justify-content: center;
  align-items: center;
  color: white;
`;

const ErrorText = styled.div`
  font-size: 0.8em;
  min-width: 200px;
  padding: 0 10px;
    font-weight: 600;
      color: white;

`;

const ErrorBox = ({close, children}) => (
    <ErrorContainer><ErrorIcon><RiErrorWarningLine size="30"/></ErrorIcon><ErrorText>{children}</ErrorText>{close ? <CloseIcon onClick={close}><RiCloseLine/></CloseIcon> : null}</ErrorContainer>
);

export default ErrorBox;
