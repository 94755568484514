import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {connect} from 'react-redux';
import {RiArrowRightLine, RiCheckLine, RiSubtractLine, RiAddLine} from 'react-icons/ri';
import {validDeviceId, simplifyId, validInstallationCode} from "../../utils/ui";
import Button from "../../components/button";
import CameraView from "../camera-view";
import InstallTile from "../../components/install-tile";
import Background from "../../components/background";
import Moment from 'moment';
import {installDevice, removeDevice, clearDeviceStatus, getVinData, getRegData, getInstallData} from "../../redux/actions/device";
import DialogBox from "../../components/dialogbox";
import ErrorBox from "../../components/errorbox";
import {clearErrors} from "../../redux/actions/shared";
import GetLogo from "../../components/getlogo";
import Categories from "../../components/categories";
import Input from "../../components/input";
import getBaseTheme from "../../utils/get-base-theme";
import Tile from "../../components/tile";
import Notes from "../../components/notes";
import PlateInput from "../../components/plate-input";
import device from "../../redux/reducers/device";

const Logo = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

const PrimaryLogo = styled(Logo)`
    margin-top: 40px;
    width: 150px;
`;

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.4em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
`;

const DateBox = styled.div`
  display: flex;
  width: 100%;
  minHeight: 50px;
  background: white;
  minWidth: 100%;
  border: solid 2px rgb(204, 204, 204);
  margin-top: 10px;
  flex: 1;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 500;
`;

const BackButtonIcon = styled.img`
  width: 42px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
    //border: solid 1px blue;
    //min-width: 320px !important;
    //padding: 0 10px 0 50px;
    flex: 1;
    padding-bottom: 20px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 40px;
    flex: 1;
`;

const TopRow = styled.span`
  display: flex;
  align-items: flex-start;
  //flex: 1;
  justify-content: flex-end;
  //border: solid orange 2px;
  width: 100%;
  min-height: 110px;
  padding: 10px 15px;
`;

const Circle = styled.div`
  height: 50px;
  width: 50px;
  background: ${props => props.type === 'add' ? props.theme.primaryTwo : props.theme.primaryTwoAlt};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${props => props.type === 'add' ? props.theme.primaryTwo : props.theme.primaryTwoAlt};
`;

const SubHeading = styled.span`
  font-size: 1em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
`;

const DialogConfirmRow = styled.div`
  width: 100%;
  display: flex;
`;

const categoriesList = ['SUVD', 'PDI', 'LOT 8', 'BLUE'];

const InstallScreen = (props) => {
    const {type} = useParams();

    const [qrCode, setQRCode] = useState(null);
    const [carReg, setCarReg] = useState(null);
    const [installationQRCode, setInstallationQRCode] = useState(null);
    const [openQRCode, setOpenQRCode] = useState(false);
    const [openInstallationQRCode, setOpenInstallationQRCode] = useState(false);
    const [openVinCode, setOpenVinCode] = useState(false);
    const [qrError, setQRError] = useState(null);
    const [fetchingVinData, setFetchingVinData] = useState(false);
    const [fetchingInstallData, setFetchingInstallData] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [step, setStep] = useState(0);
    const [enterCarReg, setEnterCarReg] = useState(false);
    const [hidePlate, setHidePlate] = useState(false);
    const [deviceId, setDeviceId] = useState(null);

    const [openDetails, setOpenDetails] = useState(false);
    const [openInstallDataDetails, setOpenInstallDataDetails] = useState(false);

    const [text, setText] = useState('');

    const localDate = Moment(new Date()).local();
    const formattedDate = localDate.format('DD - MMMM - YYYY');

    const navTo = route => (e) => {
        e.preventDefault();
        console.log(props);
        props.history.push('/' + route);
    };

    const openDetailsDialog = (remoteGetter, localGetter, setLocalGetter, openDetailsSetter, condition) => {
        if (remoteGetter && !localGetter) {
            setLocalGetter(true)
        }

        if (!remoteGetter && localGetter && condition) {
            setLocalGetter(false);
            openDetailsSetter(true);
        }

        if (!remoteGetter && localGetter && !condition) {
            setLocalGetter(false);
        }
    };

    useLayoutEffect(() => {
        // props.clearDeviceStatus();
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        openDetailsDialog(
            props.device.isGettingVinData,
            fetchingVinData,
            setFetchingVinData,
            setOpenDetails,
            (props.device.vinData
                && (props.device.vinData.vin || props.device.vinData.plate))
        );

        openDetailsDialog(
            props.device.isGettingInstallData,
            fetchingInstallData,
            setFetchingInstallData,
            setOpenInstallDataDetails,
            (props.device.vinData
                && (props.device.vinData.vin || props.device.vinData.plate)));

        // console.log('!!!!', props.device.vinData);
        // console.log('***', (props.device.vinData
        //     && (props.device.vinData.vin || props.device.vinData.plate)));

        if(openDetails) {
            setEnterCarReg(false);
        }

    }, [
        setOpenVinCode,
        setFetchingVinData,
        fetchingVinData,
        setOpenDetails,
        openDetails,
        fetchingInstallData,
        setFetchingInstallData,
        setOpenInstallDataDetails,
        props.device.isGettingVinData,
        props.device.vinData,
        openDetailsDialog
    ]);

    const fetchVinData = (code) => {
        props.getVinData({
            groupId: props.dealers.dealer._id,
            siteId: props.dealers.site._id,
            vin: code,
        });
    };

    const fetchRegData = () => {
        props.getRegData({
            groupId: props.dealers.dealer._id,
            siteId: props.dealers.site._id,
            plate: carReg.toLowerCase().replace(/\s/g, ''),
        });
    };

    const validateQRCode = (code) => {
        const validId = validDeviceId(code);

        if (!validId) {
            setQRError('Error: Invalid Intelizzz ID')
        }

        if (validId) {
            setQRCode(code);
            setOpenQRCode(false);
            setStep(step + 1);
        }
    };

    const validateInstallationQRCode = (code) => {
        const validId = validInstallationCode(code);

        if (!validId) {
            setQRError('Error: Invalid Installation Code')
        }

        if (validId) {
            setInstallationQRCode(code);
            setOpenInstallationQRCode(false);
            setStep(step + 1);
        }
    };

    const getInstallData = (code) => {
        const plate = carReg.toLowerCase().replace(/\s/g, '')
        const validId = !plate ? validInstallationCode(code) : null;

        if (!plate && !validId) {
            setQRError('Error: Invalid Installation Code');
        }

        const installDataObj = {
            groupId: props.dealers.dealer._id,
            siteId: props.dealers.site._id,
        }

        if(plate) installDataObj.plate = plate;

        if(validId) {
            setInstallationQRCode(code);
            installDataObj.installCode = code;
        }

        if (validId || plate) {
            props.getInstallData(installDataObj);
        }
    };

    // const getPlateData = () => {
    //
    // }

    const back = (e) => {
        if (e) e.preventDefault();
        props.clearErrors();
        props.clearDeviceStatus();
        props.history.goBack();
    };

    const submit = () => type === 'add' ? add() : remove();

    const add = () => {
        const {vin, plate, ...vinData} = props.device.vinData;
        const category =
            props.dealers.site
            && props.dealers.site.categories
            && props.dealers.site.categories.length > 0
            && props.dealers.site.categories[selectedCategory]
                ? props.dealers.site.categories[selectedCategory] : '';

        const payload = {
            _id: qrCode,
            groupId: props.dealers.dealer._id,
            siteId: props.dealers.site._id,
            vin: vin,
            plate: carReg && carReg.replace(/\s+/g, ''),
            data: vinData,
            category,
            installCode: installationQRCode,
            note: text,
        };

        props.install(payload);
    };

    const remove = () => {
        const payload = {
            groupId: props.dealers.dealer._id,
            siteId: props.dealers.site._id,
        };

        if(installationQRCode) payload.installCode = installationQRCode;
        if(carReg) payload.plate = carReg.replace(/\s+/g, '');
        if(deviceId) payload.deviceId = deviceId;

        props.remove(payload);
    };

    const close = () => {
        props.clearDeviceStatus();
        back(null);
    };

    const change = (e) => {
        setCarReg(e.target.value);
    };

    const clearCarReg = () => {
      setEnterCarReg(false);
      setCarReg(null);
    };

    const ConfirmBox = ({title, yes, no, open, children}) => {
        return (
            <DialogBox
                visible={open}
                top="25%" left="5%" width="10%" height="auto"
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    padding: '2%',
                    height: '100%'
                }}>
                    <p style={{textAlign: 'center', fontSize: '1.3em'}}>{title}</p>
                    {children}
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <Button onClick={yes}>YES</Button>
                        &nbsp;
                        <Button onClick={no}>NO</Button>
                    </div>
                </div>
            </DialogBox>)
    };

    const usIdentifier = (country, type) => {
        const us = <InstallTile
            style={{marginTop: 10}}
            subtitle="VIN ID NUMBER"
            status={type === 'add' ? "primaryTwo" : "primaryThree"}
            textStatus="light"
            onClick={() => {
                props.clearErrors();
                setOpenVinCode(true);
            }}
            scanResult={props.device.vinData && props.device.vinData.vin ?
                <div style={{textAlign: 'left'}}>
                    <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                        <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>make</li>
                        <li style={{
                            fontSize: '0.6em',
                            paddingBottom: 4
                        }}>{props.device.vinData.make}</li>
                        <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>model</li>
                        <li style={{
                            fontSize: '0.6em',
                            paddingBottom: 4
                        }}>{props.device.vinData.model}</li>
                        <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>vin</li>
                        <li style={{
                            fontSize: '0.6em',
                            paddingBottom: 4
                        }}>{props.device.vinData.vin}</li>
                    </ul>
                </div>
                : null}
            expanded={step === 0}
        />;

        const _uk = <InstallTile
            style={{marginTop: 10}}
            subtitle="REGISTRATION PLATE"
            status={type === 'add' ? "primaryTwo" : "primaryThree"}
            onClick={() => {
                props.clearErrors();
                setEnterCarReg(true);
            }}
            entryType='keyboard'
            scanResult={props.device.vinData && props.device.vinData.plate ?
                <div style={{textAlign: 'left'}}>
                    <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                        <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>make</li>
                        <li style={{
                            fontSize: '0.6em',
                            paddingBottom: 4
                        }}>{props.device.vinData.make}</li>
                        <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>model</li>
                        <li style={{
                            fontSize: '0.6em',
                            paddingBottom: 4
                        }}>{props.device.vinData.model}</li>
                        <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>reg plate</li>
                        <li style={{
                            fontSize: '0.6em',
                            paddingBottom: 4
                        }}>{carReg.toUpperCase()}</li>
                    </ul>
                </div>
                : null}
            expanded={step === 0}
        />;

        const uk = step === 0 ? <div>
            <PlateInput onChange={change} value={carReg} showSubmit={!!carReg} error={props.device.vinError || props.device.deviceDataError} onClick={type === 'add' ? fetchRegData : getInstallData}/>
        </div> : _uk;

        return country === 'United Kingdom' ? uk : us;
    };

    return (
        <>
            {openVinCode || openQRCode ? null : <>
                <DialogBox
                    visible={props.device.isInstalling}
                    top="25%" left="5%" width="10%" height="auto"
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        padding: '2%',
                        height: '100%'
                    }}>
                        <p style={{textAlign: 'center', fontSize: '1.3em'}}>Installing</p>
                    </div>
                </DialogBox>
                <DialogBox visible={props.device.isInstalled} top="25%" left="5%" width="10%" height="auto">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        padding: '2%',
                        height: '100%'
                    }}>
                        <p style={{textAlign: 'center', fontSize: '1.3em'}}>Installed</p>
                        <RiCheckLine size="5em" color="green"/>
                        <Button status="primaryTwo" textStatus="light" onClick={close}>Close</Button>
                    </div>
                </DialogBox>
                <DialogBox
                    visible={props.device.isRemoving}
                    top="25%" left="5%" width="10%" height="auto"
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        padding: '2%',
                        height: '100%'
                    }}>
                        <p style={{textAlign: 'center', fontSize: '1.3em'}}>Removing ...</p>
                    </div>
                </DialogBox>
                <DialogBox
                    visible={props.device.isRemoved}
                    top="25%" left="5%" width="10%" height="auto"
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        padding: '2%',
                        height: '100%'
                    }}>
                        <p style={{textAlign: 'center', fontSize: '1.3em'}}>Removed</p>
                        <RiCheckLine size="5em" color="green"/>
                        <Button onClick={close}>Close</Button>
                    </div>
                </DialogBox>
                <DialogBox
                    visible={enterCarReg}
                    top="20px" left="2.5%" width="5%" height="5%"
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        padding: '10px',
                        height: '100%'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            flexDirection: 'column',
                            width: '100%',
                        }}>
                        <div style={{margin: '10px 0 8px 0', width: '100%', textAlign: 'center', borderBottom: '1px solid rgb(220, 220, 220)', paddingBottom: 5}}>
                            <SubHeading>ENTER NUMBER PLATE</SubHeading>
                        </div>
                        <Input
                            title="REG PLATE"
                            style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                                width: '100%',
                                marginTop: '10px',
                                marginBottom: '50px'
                            }}
                            onChange={change}
                            placeholder='NUMBER PLATE'
                            name='username'
                            confirm={carReg ? <RiCheckLine size="22" color="green"/> : null}
                        />
                        </div>
                        <DialogConfirmRow>
                            <Button style={{marginRight: 5}} onClick={fetchRegData}>Submit</Button>
                            <Button onClick={clearCarReg}>Cancel</Button>
                        </DialogConfirmRow>
                    </div>
                </DialogBox>
                <ConfirmBox
                    title='Are these details correct?'
                    yes={
                        () => {
                            setStep(step + 1);
                            setOpenVinCode(false);
                            setOpenDetails(false);
                        }
                    }
                    no={
                        () => {
                            props.clearDeviceStatus();
                            setOpenDetails(false);
                            setEnterCarReg(true);
                        }
                    }
                    open={openDetails}
                >
                    {props.device.vinData && (props.device.vinData.vin || props.device.vinData.plate) ?
                        <div style={{textAlign: 'left', width: '100%', padding: '0 10px 20px 10px'}}>
                            <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>make</li>
                                <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.make}</li>
                                <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>model</li>
                                <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.model}</li>
                                <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>{props.device.vinData.vin ? 'vin' : 'reg plate'}</li>
                                <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.vin || (carReg && carReg.toUpperCase()) || (props.device.vinData.plate && props.device.vinData.plate.toUpperCase())}</li>
                            </ul>
                        </div>
                        : null}
                </ConfirmBox>

                <Background>
                    <div style={{position: 'absolute', width: 80, left: 0}}><Button
                        style={{textAlign: 'left', paddingTop: 15, paddingLeft: 15}}
                        status="invisible" onClick={back}><BackButtonIcon
                        src="/assets/images/shapes/back-button-icon.svg"/></Button>
                    </div>
                    <TopRow>
                        <Circle type={type}>
                            {type === 'add' ?
                                <RiAddLine size="80" color="white" />
                                : <RiSubtractLine size="80" color="white" />}
                        </Circle>
                    </TopRow>
                    <GetLogo logoData={props.dealers.dealer} />
                    <Container>
                        {props.device.error ?
                            <ErrorBox close={props.clearErrors}>{props.device.error}</ErrorBox> : null}
                        {/*<pre>VIN CODE: {vinCode}</pre>*/}
                        {/*<pre>QR CODE: {qrCode}</pre>*/}
                        {/*<pre>VIN DATA: {JSON.stringify(vinData, null, 2)}</pre>*/}
                        {!hidePlate ? usIdentifier(props.dealers && props.dealers.dealer && props.dealers.dealer.country, type) : null}
                        {type === 'add' ? <InstallTile
                            style={{marginTop: 20}}
                            subtitle="INTELIZZZ QR CODE"
                            status={type === 'add' ? "primaryTwo" : "primaryThree"}
                            textStatus="light"
                            onClick={() => {
                                setQRError(null);
                                setOpenQRCode(true);
                            }}
                            scanType="qrcode"
                            scanResult={qrCode ? simplifyId(qrCode) : null}
                            expanded={step === 1}
                        /> : null}
                        <InstallTile
                            style={{marginTop: 20}}
                            subtitle={hidePlate ? "INSTALLATION DETAILS" : "INSTALLATION QR CODE"}
                            status={type === 'add' ? "primaryTwo" : "primaryThree"}
                            textStatus="light"
                            onClick={() => {
                                setQRError(null);
                                setOpenInstallationQRCode(true);
                            }}
                            scanType="qrcode"
                            scanResult={type === 'add' ? installationQRCode :
                                props.device.vinData && (props.device.vinData.vin || props.device.vinData.plate) ?
                                    <div style={{textAlign: 'left'}}>
                                        <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                            <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>device id</li>
                                            <li style={{
                                                fontSize: '0.6em',
                                                paddingBottom: 4
                                            }}>{props.device.vinData.deviceId}</li>
                                            <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>make</li>
                                            <li style={{
                                                fontSize: '0.6em',
                                                paddingBottom: 4
                                            }}>{props.device.vinData.make}</li>
                                            <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>model</li>
                                            <li style={{
                                                fontSize: '0.6em',
                                                paddingBottom: 4
                                            }}>{props.device.vinData.model}</li>
                                            <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>{props.device.vinData.vin ? 'vin' : 'plate'}</li>
                                            <li style={{
                                                fontSize: '0.6em',
                                                paddingBottom: 4
                                            }}>{props.device.vinData.vin || (props.device.vinData.plate && props.device.vinData.plate.toUpperCase())}</li>
                                        </ul>
                                    </div>
                                    : null}
                            expanded={type === 'add' ? step >= 2 : step === 0}
                        />

                        {type !== 'add' && false ? <InstallTile
                            style={{marginTop: 10}}
                            subtitle="VIN ID NUMBER"
                            status={type === 'add' ? "primaryTwo" : "primaryThree"}
                            textStatus="light"
                            onClick={() => {
                                props.clearErrors();
                                // setOpenVinCode(true);
                            }}
                            scanResult={props.device.vinData && props.device.vinData.vin ?
                                <div style={{textAlign: 'left'}}>
                                    <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                        <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>make</li>
                                        <li style={{
                                            fontSize: '0.6em',
                                            paddingBottom: 4
                                        }}>{props.device.vinData.make}</li>
                                        <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>model</li>
                                        <li style={{
                                            fontSize: '0.6em',
                                            paddingBottom: 4
                                        }}>{props.device.vinData.model}</li>
                                        <li style={{fontWeight: 'bold', fontSize: '0.4em'}}>vin</li>
                                        <li style={{
                                            fontSize: '0.6em',
                                            paddingBottom: 4
                                        }}>{props.device.vinData.vin}</li>
                                    </ul>
                                </div>
                                : null}
                            expanded={true}
                        /> : null}
                        <div style={{
                            display: 'flex',
                            flex: 1,
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            paddingBottom: 20,
                            // border: 'solid 1px orange',
                        }}>
                            <Footer>
                                {type === 'add' ? <Notes text={text} saveText={setText} disabled={step < 2}/> : null}
                                {type === 'add' ? <Categories
                                    siteName={props.dealers.site && props.dealers.site.name}
                                    status={type === 'add' ? "primaryTwo" : "primaryThree"}
                                    textStatus="light"
                                    categoriesList={props.dealers.site && props.dealers.site.categories}
                                    selected={selectedCategory}
                                    selectedName={selectedCategory !== null ? props.dealers.site && props.dealers.site.categories[selectedCategory] : ''}
                                    setSelected={setSelectedCategory}
                                    disabled={step < 2 }
                                /> : null}
                                <DateBox>{formattedDate.toUpperCase()}</DateBox>
                                <Button onClick={submit}
                                        disabled={(type === 'add' && step < 2) || (type !== 'add' && step < 1)}
                                        status={type === 'add' ? "primaryThree" : "primaryTwo"} textStatus="light"
                                        style={{marginTop: 10, maxWidth: 450, display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                                    <div style={{flex: 1}}>&nbsp;</div><ButtonText>SUBMIT</ButtonText><RiArrowRightLine size="30" style={{flex: 1}} />
                                </Button>
                            </Footer>
                        </div>
                    </Container>
                </Background></>}

            {openVinCode ?
                <CameraView
                    close={() => setOpenVinCode(false)}
                    result={fetchVinData}
                    error={props.device.vinError}
                    dialog={<ConfirmBox
                        title='Are these details correct?'
                        yes={
                            () => {
                                setStep(step + 1);
                                setOpenVinCode(false);
                                setOpenDetails(false);
                            }
                        }
                        no={
                            () => {
                                props.clearDeviceStatus();
                                setOpenDetails(false);
                            }
                        }
                        open={openDetails}
                    >
                        {props.device.vinData && props.device.vinData.vin ?
                            <div style={{textAlign: 'left', width: '100%', padding: '0 10px 20px 10px'}}>
                                <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                    <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>make</li>
                                    <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.make}</li>
                                    <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>model</li>
                                    <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.model}</li>
                                    <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>vin</li>
                                    <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.vin}</li>
                                </ul>
                            </div>
                            : null}
                    </ConfirmBox>}
                /> : null}

            {openQRCode ?
                <CameraView
                    close={() => setOpenQRCode(false)}
                    result={validateQRCode}
                    error={qrError}/> : null}

            {openInstallationQRCode ?
                <CameraView
                    close={() => setOpenInstallationQRCode(false)}
                    result={type === 'add' ? validateInstallationQRCode : getInstallData}
                    error={type === 'add' ? qrError : props.device.deviceDataError}
                    dialog={<ConfirmBox
                    title='Are these details correct?'
                    yes={
                        () => {
                            setStep(step + 1);
                            setOpenInstallationQRCode(false);
                            setOpenInstallDataDetails(false);
                        }
                    }
                    no={
                        () => {
                            props.clearDeviceStatus();
                            setInstallationQRCode(null);
                            setOpenInstallDataDetails(false);
                        }
                    }
                    open={openInstallDataDetails}
            >
            {props.device.vinData && (props.device.vinData.vin || props.device.vinData.plate) ?
                <div style={{textAlign: 'left', width: '100%', padding: '0 10px 20px 10px'}}>
                    <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                        <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>device id</li>
                        <li style={{
                            fontSize: '0.9em',
                            paddingBottom: 8
                        }}>{simplifyId(props.device.vinData.deviceId)}</li>
                        <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>make</li>
                        <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.make}</li>
                        <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>model</li>
                        <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.model}</li>
                        <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>{props.device.vinData.vin ? 'vin' : 'plate'}</li>
                        <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.vin || (props.device.vinData.plate && props.device.vinData.plate.toUpperCase())}</li>
                    </ul>
                </div>
                : null}
        </ConfirmBox>} /> : null}

            {openInstallDataDetails && <ConfirmBox
                title='Are these details correct?'
                yes={
                    () => {
                        setStep(step + 1);
                        setDeviceId(props?.device?.vinData?.deviceId);
                        setOpenInstallationQRCode(false);
                        setOpenInstallDataDetails(false);
                        setHidePlate(true);
                    }
                }
                no={
                    () => {
                        props.clearDeviceStatus();
                        setInstallationQRCode(null);
                        setOpenInstallDataDetails(false);
                        setCarReg(null);
                        setDeviceId(null);
                    }
                }
                open={setOpenInstallDataDetails}
            >
                {props.device.vinData && (props.device.vinData.vin || props.device.vinData.plate) ?
                    <div style={{textAlign: 'left', width: '100%', padding: '0 10px 20px 10px'}}>
                        <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                            <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>device id</li>
                            <li style={{
                                fontSize: '0.9em',
                                paddingBottom: 8
                            }}>{simplifyId(props.device.vinData.deviceId)}</li>
                            <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>make</li>
                            <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.make}</li>
                            <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>model</li>
                            <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.model}</li>
                            <li style={{fontWeight: 'bold', fontSize: '0.8em'}}>{props.device.vinData.vin ? 'vin' : 'plate'}</li>
                            <li style={{fontSize: '0.9em', paddingBottom: 8}}>{props.device.vinData.vin || (props.device.vinData.plate && props.device.vinData.plate.toUpperCase())}</li>
                        </ul>
                    </div>
                    : null}
            </ConfirmBox>}
        </>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        device: state.device,
        dealers: state.dealers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        install: (payload) => dispatch(installDevice(payload)),
        remove: (payload) => dispatch(removeDevice(payload)),
        clearDeviceStatus: () => dispatch(clearDeviceStatus()),
        getVinData: (payload) => dispatch(getVinData(payload)),
        getRegData: (payload) => dispatch(getRegData(payload)),
        getInstallData: (payload) => dispatch(getInstallData(payload)),
        clearErrors: () => dispatch(clearErrors()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallScreen);
