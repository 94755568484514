import {
    GET_DEALERS_REQUEST,
    GET_DEALERS_SUCCESS,
    GET_DEALERS_FAILURE,
    CLEAR_DEALERS_REQUEST,
    GET_SITES_REQUEST,
    GET_SITES_SUCCESS,
    GET_SITES_FAILURE,
    SET_DEALER
} from "../actionTypes/types";
import api from '../../utils/api';
import Config from '../../config';

const ZZZAPI = process.env.REACT_APP_ZZZAPI;
const PREFIX = Config.prefix;

const getDealersRequest = () => {
    return {
        type: GET_DEALERS_REQUEST
    }
};

export const getDealersSuccess = (payload) => {
    return {
        type: GET_DEALERS_SUCCESS,
        payload
    }
};

export const getDealersFailure = (payload) => {
    return {
        type: GET_DEALERS_FAILURE,
        payload
    }
};

export const getDealers = (payload) => {
    return (dispatch) => {
        dispatch(getDealersRequest());

        const success = (result) => {
            dispatch(getDealersSuccess(result));
        };

        const failure = (err) => {
            dispatch(getDealersFailure(err))
        };

        return api(
            `${ZZZAPI}/api/installer/dealers`,
            'GET',
            {
                'prefix': PREFIX
            },
            success,
            failure,
        );

        // return api(
        //     `${ZZZAPI}/api/installer/dealers?search=${payload}`,
        //     'GET',
        //     {
        //         'prefix': PREFIX
        //     },
        //     success,
        //     failure,
        // );
    }
};

export const clearDealers = ()=> {
    return {
        type: CLEAR_DEALERS_REQUEST
    }
};

const getSitesRequest = () => {
    return {
        type: GET_SITES_REQUEST
    }
};

export const getSitesSuccess = (payload) => {
    return {
        type: GET_SITES_SUCCESS,
        payload
    }
};

export const getSitesFailure = (payload) => {
    return {
        type: GET_SITES_FAILURE,
        payload
    }
};

export const getSites = (payload) => {
    return (dispatch) => {
        // console.log('PAYLOADD: ', payload);
        dispatch(getSitesRequest());

        const success = (result) => {
            dispatch(getSitesSuccess(result));
        };

        const failure = (err) => {
            dispatch(getSitesFailure(err))
        };

        return api(
            `${ZZZAPI}/api/installer/sites?dealerid=${payload}`,
            'GET',
            {
                'prefix': PREFIX
            },
            success,
            failure,
        );
    }
};

export const setDealer = (payload) => {
    return {
        type: SET_DEALER,
        payload
    }
};
