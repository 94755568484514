import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE, CLEAR_ERRORS
} from "../actionTypes/types";
import Config from '../../config';

const PREFIX = Config.prefix;

const initialState = {
  username: '',
  firstName: '',
  lastName: '',
  isAuthenticating: false,
  isAuthenticated: document.cookie.indexOf(`zzz${PREFIX}user={`) !== -1,
  isLoggingOut: false,
  error: null,
};

const AuthReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case AUTH_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        error: null
      };
    case AUTH_SUCCESS:
      const { username, firstName, lastName } = payload;
      return {
        ...state,
        username,
        firstName,
        lastName,
        isAuthenticating: false,
        isAuthenticated: true,
        error: null
      };
    case AUTH_FAILURE:
      return {
        ...state,
        error: payload.message,
        isAuthenticating: false,
        isAuthenticated: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        error: null,
        isLoggingOut: true
      };
    case LOGOUT_SUCCESS:
      return initialState;
    case LOGOUT_FAILURE:
      return {
        ...state,
        error: payload.message,
        isLoggingOut: false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

export default AuthReducer;
