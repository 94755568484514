import React, {useState, useEffect, useLayoutEffect} from 'react';
import styled, {css} from 'styled-components';
import getBaseTheme from "../../utils/get-base-theme";
import Button from "../button";

const NotesContainer = styled.div`
  position: relative;
  margin-top: 10px;
  //border: solid black 1px;
  flex: 1;
  width: 100%;
  padding: 4px 14px;
  color: white;
  border-radius: 6px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  
  ${props => css`${getBaseTheme(props)}`};
  
  background: rgb(250, 178, 50);
`;

const NotesIcon = styled.img`
  width: 22px;
`;

const WritingIcon = styled.img`
  width: 52px;
`;

const AddNotesComponent = styled.div`
  width: 100%;
  flex: 1;
  //background: #000;
  position: absolute;
  ${props => css`${getBaseTheme(props)}`};
  top: -500px;
  bottom: 0;
  border-radius: 15px;
  background: rgb(250, 178, 50);
`;

const NotesIconContainer = styled.div`
  background-color: rgb(250, 178, 50);
  border-radius: 20px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center
`;

const AddNotesShower = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotesHeader = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 4px 24px;
  display: flex;
  justify-content: space-between;
`;

const NotesFooter = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 4px 24px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
`;

const NoteArea = styled.textarea`
  width: 100%;
  height: 100%;
  background: transparent;
  line-height: 30px;
  border: none;
  padding: 10px;
  resize: none;
  //margin-top: 10px;
  position: absolute;
`;

const Disabled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: inherit;
    background: white;
    opacity: 0.6;
`;

const lines = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const AddNotes = ({text, textStatus, close, onChange, save}) => {
    const lineOut = lines.map((line) => <div key={'line' + line}
                                             style={{borderTop: 'solid black 1px', width: '100%', minHeight: 30}}/>);

    return (
        <AddNotesComponent textStatus={textStatus}>
            <NotesHeader>
                <div>Notes</div>
                <WritingIcon src='/assets/images/shapes/writing-icon.svg'/>
            </NotesHeader>
            <div style={{marginTop: 10}}>
                <div style={{height: '50%', position: 'absolute', width: '100%'}}>
                    <NoteArea onChange={onChange} defaultValue={text} />
                </div>
                <div style={{paddingTop: '2px'}}>
                    <>{lineOut}</>
                </div>
            </div>

            <NotesFooter>
                <Button status="invisible" textStatus="dark" onClick={save}>Save</Button>
                <Button status="invisible" textStatus="dark" onClick={close}>Cancel</Button>
            </NotesFooter>
        </AddNotesComponent>
    );
};

const Notes = ({saveText, text, disabled}) => {
    const [showAddNotes, setShowAddNotes] = useState(false);
    const [content, setContent] = useState('');

    const close = () => setShowAddNotes(false);
    const save = () => {
        saveText(content);
        close();
    };

    return (
        <NotesContainer
            status={"primaryTwo"}
            textStatus="dark"
        >
            {disabled ? <Disabled /> : null}

            {showAddNotes ? <AddNotes text={text} textStatus="dark" close={close} save={save}
                                      onChange={(e) => setContent(e.target.value)} value={text} /> : null}
            <AddNotesShower onClick={() => setShowAddNotes(!showAddNotes)}>
                <div>{text.trim().length > 0 ? text.substring(0, 60) + '...' : 'Add Note...'}</div>
                <div>&nbsp;</div>
                <NotesIconContainer><NotesIcon src='/assets/images/shapes/pencil-icon.svg'/></NotesIconContainer>
            </AddNotesShower>
        </NotesContainer>
    );
};

export default Notes;
