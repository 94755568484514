export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const INSTALL_DEVICE_REQUEST = 'INSTALL_DEVICE_REQUEST';
export const INSTALL_DEVICE_SUCCESS = 'INSTALL_DEVICE_SUCCESS';
export const INSTALL_DEVICE_FAILURE = 'INSTALL_DEVICE_FAILURE';

export const REMOVE_DEVICE_REQUEST = 'REMOVE_DEVICE_REQUEST';
export const REMOVE_DEVICE_SUCCESS = 'REMOVE_DEVICE_SUCCESS';
export const REMOVE_DEVICE_FAILURE = 'REMOVE_DEVICE_FAILURE';

export const CLEAR_DEVICE_STATUS = 'CLEAR_DEVICE_STATUS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const GET_DEALERS_REQUEST = 'GET_DEALERS_REQUEST';
export const GET_DEALERS_SUCCESS = 'GET_DEALERS_SUCCESS';
export const GET_DEALERS_FAILURE = 'GET_DEALERS_FAILURE';

export const CLEAR_DEALERS_REQUEST = 'CLEAR_DEALERS_REQUEST';

export const GET_SITES_REQUEST = 'GET_SITES_REQUEST';
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const GET_SITES_FAILURE = 'GET_SITES_FAILURE';

export const GET_VINDATA_REQUEST = 'GET_VINDATA_REQUEST';
export const GET_VINDATA_SUCCESS = 'GET_VINDATA_SUCCESS';
export const GET_VINDATA_FAILURE = 'GET_VINDATA_FAILURE';

export const GET_INSTALLDATA_REQUEST = 'GET_INSTALLDATA_REQUEST';
export const GET_INSTALLDATA_SUCCESS = 'GET_INSTALLDATA_SUCCESS';
export const GET_INSTALLDATA_FAILURE = 'GET_INSTALLDATA_FAILURE';

export const SET_DEALER = 'SET_DEALER';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const STORE_USER_DATA = 'STORE_USER_DATA';

export const CHECK_WEB_ID_REQUEST = 'CHECK_WEB_ID_REQUEST';
export const CHECK_WEB_ID_SUCCESS = 'CHECK_WEB_ID_SUCCESS';
export const CHECK_WEB_ID_FAILURE = 'CHECK_WEB_ID_FAILURE';

export const SEND_REQUEST_REQUEST = 'SEND_REQUEST_REQUEST';
export const SEND_REQUEST_SUCCESS = 'SEND_REQUEST_SUCCESS';
export const SEND_REQUEST_FAILURE = 'SEND_REQUEST_FAILURE';
