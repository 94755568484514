import {
  INSTALL_DEVICE_REQUEST,
  INSTALL_DEVICE_SUCCESS,
  INSTALL_DEVICE_FAILURE,
  REMOVE_DEVICE_REQUEST,
  REMOVE_DEVICE_SUCCESS,
  REMOVE_DEVICE_FAILURE,
  CLEAR_DEVICE_STATUS,
  CLEAR_ERRORS,
  GET_VINDATA_REQUEST,
  GET_VINDATA_SUCCESS,
  GET_VINDATA_FAILURE,
  GET_INSTALLDATA_REQUEST,
  GET_INSTALLDATA_SUCCESS,
  GET_INSTALLDATA_FAILURE,
} from "../actionTypes/types";

const initialState = {
  error: null,
  isInstalling: false,
  isInstalled: false,
  isRemoving: false,
  isRemoved: false,
  vinData: null,
  isGettingVinData: false,
  vinError: null,
  deviceData: null,
  isGettingDeviceData: false,
  deviceDataError: null,
};

const DeviceReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case INSTALL_DEVICE_REQUEST:
      return {
        ...state,
        isInstalling: true,
        isInstalled: false,
        error: null
      };
    case INSTALL_DEVICE_SUCCESS:
      return {
        ...state,
        isInstalling: false,
        isInstalled: true,
        error: null
      };
    case INSTALL_DEVICE_FAILURE:
      return {
        ...state,
        error: payload.message,
        isInstalling: false,
        isInstalled: false,
      };
    case REMOVE_DEVICE_REQUEST:
      return {
        ...state,
        isRemoving: true,
        isRemoved: false,
        error: null
      };
    case REMOVE_DEVICE_SUCCESS:
      return {
        ...state,
        isRemoving: false,
        isRemoved: true,
        error: null
      };
    case REMOVE_DEVICE_FAILURE:
      return {
        ...state,
        error: payload.message,
        isRemoving: false,
        isRemoved: false,
      };
    case GET_VINDATA_REQUEST:
      return {
        ...state,
        error: null,
        vinError: null,
        isGettingVinData: true,
      };
    case GET_VINDATA_SUCCESS:
      const vinError = payload.status === "error" ? payload.result.message : null;
      const vinData = payload.status === "success" ? payload.result : null;
      return {
        ...state,
        vinError,
        vinData,
        isGettingVinData: false,
      };
    case GET_VINDATA_FAILURE:
      return {
        ...state,
        vinError: payload.message,
        isGettingVinData: false,
      };
    case GET_INSTALLDATA_REQUEST:
      return {
        ...state,
        error: null,
        deviceDataError: null,
        isGettingInstallData: true,
      };
    case GET_INSTALLDATA_SUCCESS:
      return {
        ...state,
        deviceDataError: null,
        vinData: {
          ...payload.vehicleData,
          vin: payload.vin,
          plate: payload.plate,
          deviceId: payload.deviceId,
          category: payload.category
        },
        isGettingInstallData: false,
      };
    case GET_INSTALLDATA_FAILURE:
      return {
        ...state,
        vinError: null,
        deviceDataError: payload.message,
        isGettingInstallData: false,
        vinData: null,
      };
    case CLEAR_DEVICE_STATUS:
      return {
        ...state,
        error: null,
        isInstalling: false,
        isInstalled: false,
        isRemoving: false,
        isRemoved: false,
        vinError: null,
        vinData: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        vinError: null,
        deviceDataError: null,
      };
    default:
      return state;
  }
};

export default DeviceReducer;
