import React, { useState } from 'react';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import {Router, Route, Switch, Redirect, withRouter} from "react-router-dom";
import {
    Provider,
    connect
} from 'react-redux';
import StartScreen from "../screens/start-screen";
import RegisterScreen from "../screens/register-screen";
import RegisterInstructionsScreen from "../screens/register-instructions-screen";
import QuizzerScreen from "../screens/quizzer-screen";
import SigninScreen from "../screens/signin-screen";
import DeviceManagementScreen from "../screens/device-management-screen";
import InstallScreen from "../screens/install-screen";
import SiteScreen from "../screens/site-screen";
import Cookies from "../utils/cookies";
import Config from '../config';

import ZZZ_DB from "../utils/storage";
import {setDealer} from "../redux/actions/dealers";

const PREFIX = Config.prefix;

const history = createBrowserHistory();
history.listen(location => {
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname)
});


const Root = ({store}, props) => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <ProtectedRoute path='/site' component={SiteScreen}/>
                    <ProtectedRoute path='/device' component={DeviceManagementScreen}/>
                    <Route exact path='/' component={StartScreen}/>
                    <Route exact path='/register' component={RegisterScreen}/>
                    <Route exact path='/register/:token' component={RegisterScreen}/>
                    <Route exact path='/register/:token/:email/:usertype' component={RegisterScreen}/>
                    <Route exact path='/registerpdf' component={RegisterInstructionsScreen}/>
                    <Route exact path='/quizzer' component={QuizzerScreen} />
                    <Route exact path='/signin' component={SigninScreen}/>
                    <ProtectedRoute exact path='/install/:type' component={InstallScreen}/>
                </Switch>
            </Router>
        </Provider>
    )
};

const _ProtectedRoute = ({component: Component, path, redirect, auth, dealers, ...props}) => {
    const [localDBChecked, setLocalDBChecked] = useState(false);

    if (!auth.isAuthenticated) {
        // console.log('PROTECTED ROUTE LOGOUT REDIRECT');
        Cookies.delete(`zzz${PREFIX}user`);
        Cookies.delete('zzz' + PREFIX);
        return <Redirect from='/device' to='/signin'/>
    }

    if(!dealers.dealer && !dealers.site && !localDBChecked) {
        ZZZ_DB.table('dealers').toArray().then((data) => {
            if(data && data.length > 0 && data[0].hasOwnProperty('dealer') && data[0].hasOwnProperty('site')) {
                const dealer = data[0];
                props.setDealer({site: dealer.site, dealer: dealer.dealer});
                setLocalDBChecked(true);
            }
        });
    }

    return <Route path={path} component={Component}/>;
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        dealers: state.dealers
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setDealer: (payload) => dispatch(setDealer(payload)),
    }
};

const ProtectedRoute = withRouter(connect(mapStateToProps, mapDispatchToProps)(_ProtectedRoute));

export default connect(mapStateToProps, mapDispatchToProps)(Root);
