import React, {useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom';
import {RiCheckLine, RiArrowRightLine} from 'react-icons/ri'
import Button from "../../components/button";
import Input from "../../components/input";
import Background from "../../components/background";
import {authenticate} from "../../redux/actions/auth";
import {passwordValid, validateEmail} from "../../utils/ui";
import ErrorBox from '../../components/errorbox';

const Logo = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

const Hexagon = styled.img`
  width: 50px;
`;

const PrimaryLogo = styled(Logo)`
    margin-top: 40px;
`;

const AppIconLogo = styled(Logo)`
    margin-top: 45px;
`;

const Heading = styled.span`
  font-size: 1.8em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
  text-align: center;
`;

const SubHeading = styled.span`
  font-size: 1em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
    //border: solid 1px blue;
    //min-width: 320px !important;
    padding: 20px 6px 20px 25px;
    flex: 1;
`;

const StaySignedIn = styled.span`
    width: 100%;
    text-align: left;
    font-size: 0.7em;
    display: flex;
    align-items: center;
`;

const SigninScreen = (props) => {
    let [user, setUser] = useState({ appName: 'IntelizzzInstaller', role: 'installer_user' });

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    if(props.auth.isAuthenticated) {
        return <Redirect to='/site' />;
    }

    // Curried redirect function
    const navTo = route => (e) => {
        e.preventDefault();
        console.log(props);
        props.history.push('/' + route);
    };

    const change = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value})
    };

    const submit = (e) => {
        e.preventDefault();
        props.login(user);
    };

    // const back = (e) => {
    //     e.preventDefault();
    //     props.history.goBack();
    // };

    return (
        <Background>
            {props.auth.error ? <ErrorBox>{props.auth.error}</ErrorBox> : null}
            <div style={{maxWidth: 130}}><AppIconLogo src="/assets/images/logos/installer.png"/></div>
            <div style={{marginTop: 20, maxWidth: 400}}><Heading>Welcome to the</Heading></div>
            <div style={{marginTop: 3}}><Heading>Installation Network</Heading></div>
            <div style={{maxWidth: 130}}><PrimaryLogo
                src="/assets/images/logos/zzz-logo-black.png"/></div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingLeft: 15,
                paddingRight: 15
            }}>
                <div style={{margin: '25px 0 8px 0'}}><SubHeading>SIGN IN</SubHeading></div>
                <Input
                    title="ID"
                    style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                        width: 'calc(100% - 50px)',
                        marginTop: '10px'
                    }}
                    onChange={change}
                    placeholder='USER ID'
                    name='username'
                    confirm={user && user.username ? <RiCheckLine size="22" color="green"/> : null}
                />
                <Input
                    title="PASSWORD"
                    style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                        width: 'calc(100% - 50px)',
                        marginTop: '10px'
                    }}
                    type="password"
                    onChange={change}
                    placeholder='PASSWORD'
                    name='password'
                    confirm={user && passwordValid(user.password) ? <RiCheckLine size="22" color="green"/> : null}
                />
            </div>

            <Footer>
                <StaySignedIn><input type="checkbox" />KEEP ME SIGNED IN</StaySignedIn>
                <Button status="invisible" style={{width: 55, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <RiArrowRightLine style={{position: 'absolute', color: 'rgb(255,255,255)'}} size="30"
                        // onClick={navTo('device')}
                                      onClick={submit}
                                      color="red"/>
                    <Hexagon src='/assets/images/shapes/hexagonal.svg' />
                </Button>
            </Footer>
        </Background>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {auth: state.auth};
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninScreen);
