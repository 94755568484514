import {
  CHECK_WEB_ID_FAILURE,
  CHECK_WEB_ID_REQUEST,
  CHECK_WEB_ID_SUCCESS, SEND_REQUEST_FAILURE,
  SEND_REQUEST_REQUEST, SEND_REQUEST_SUCCESS,
  STORE_USER_DATA
} from "../actionTypes/types";
import Config from '../../config';

const PREFIX = Config.prefix;

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  address1: '',
  address2: '',
  address3: '',
  address4: '',
  postcode: '',
  country: '',
  dob: '',
  webId: '',
  exists: false,
  error: null,
  checkingWebId: true,
  sendingRequest: false,
  requestSent: false,
};

// const testState = {
//   firstName: 'Anthony',
//   lastName: 'Williams',
//   email: 'anthonyux@gmail.com',
//   address1: '',
//   address2: '',
//   address3: '',
//   address4: '',
//   postcode: '',
//   country: 'UK',
//   dob: '11/05/1977',
//   webId: 'SnFu1-S_5-',
//   exists: false,
//   error: null,
//   checkingWebId: true,
//   sendingRequest: false,
//   requestSent: false,
// }

const RegisterReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case STORE_USER_DATA:
      return {
        ...state,
        ...payload,
      };
    case CHECK_WEB_ID_REQUEST:
      return {
        ...state,
        checkingWebId: true,
      };
    case CHECK_WEB_ID_SUCCESS:
      return {
        ...state,
        checkingWebId: false,
        exists: payload.exists,
      };
    case CHECK_WEB_ID_FAILURE:
      return {
        ...state,
        checkingWebId: false,
        exists: false,
        error: payload.message,
      };
    case SEND_REQUEST_REQUEST:
      return {
        ...state,
        sendingRequest: true,
      };
    case SEND_REQUEST_SUCCESS:
      return {
        ...state,
        sendingRequest: false,
        requestSent: true,
      };
    case SEND_REQUEST_FAILURE:
      return {
        ...state,
        sendingRequest: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export default RegisterReducer;
