import React, {createRef, useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Redirect, useParams} from 'react-router-dom';
import {RiCheckLine, RiArrowRightLine} from 'react-icons/ri';
import {motion} from 'framer-motion';
import Button from "../../components/button";
import Input from "../../components/input";
import Background from "../../components/background";
import {authenticate} from "../../redux/actions/auth";
import {passwordValid, validateEmail} from "../../utils/ui";
import ErrorBox from '../../components/errorbox';
import DialogBox from "../../components/dialogbox";
import {checkWebId, sendRequest, storeRegistrationData} from "../../redux/actions/register";
import Spinner from "../../components/spinner";

const spinTransition = {
    loop: Infinity,
    ease: "linear",
    duration: 1
};

const Logo = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

const Hexagon = styled.img`
  width: 50px;
`;

const PrimaryLogo = styled(Logo)`
    margin-top: 40px;
`;

const AppIconLogo = styled(Logo)`
    margin-top: 45px;
`;

const Heading = styled.span`
  font-size: 1.8em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
  text-align: center;
`;

const SubHeading = styled.span`
  font-size: 1em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
    //border: solid 1px blue;
    //min-width: 320px !important;
    padding: 20px 6px 20px 25px;
    flex: 1;
`;

const FooterBack = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
    //border: solid 1px blue;
    //min-width: 320px !important;
    padding: 20px 25px;
    flex: 1;
`;

const StaySignedIn = styled.span`
    width: 100%;
    text-align: left;
    font-size: 0.7em;
    display: flex;
    align-items: center;
`;

const RegisterNote = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 12px 30px;
  font-size: 1em;
  line-height: 25px;
  color: ${props => props.theme.secondaryThree};
  background: white;
  border: 2px rgb(205,204, 204) solid;
  margin-top: 10px;
`;

const CompletedFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
    //border: solid 1px blue;
    //min-width: 320px !important;
    padding: 20px;
    //flex: 1;
`;

const PassNote = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 12px 15px;
  font-size: 1.3em;
  line-height: 25px;
  margin-top: 20px;
  color: ${props => props.theme.secondaryThree};
`;

const PassNoteBox = styled.div`
  border: 2px rgb(205,204, 204) solid;
  width: 100%;
  border-radius: 15px;
  padding: 12px 15px;
  font-size: 4em;
  font-weight: 900;
  margin-top: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;  
  height: 170px;
  background-color: ${props => props.theme.positive};
`;

const PassNoteContainer = styled.div`
  margin: 10px 20px;
  text-align: center;
`;

const usertypes = {
    u: 'upfitter_user',
    i: 'installer_user'
}

const RegisterScreen = (props) => {
    const {token, email, usertype} = useParams();
    // console.log('TOKEN: ', token);
    let [user, setUser] = useState({appName: 'IntelizzzInstaller', role: usertype ? usertypes[usertype] : 'installer_user', email: email});
    const [visible, setVisible] = useState(null);

    const checkToken = props.checkToken;

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
        checkToken(token);
    }, [checkToken, token]);

    const back = (e) => {
        e.preventDefault();
        props.history.goBack();
    };

    const change = (e) => {
        setUser({...user, [e.target.name]: e.target.value})
    };

    const requiredItems = ['firstName', 'lastName', 'email'];
    const requiredHeadings = ['name', 'email'];

    const required = (item) => {
        const foundItem = requiredItems.find(itemName => itemName === item);
        const foundHeading = requiredHeadings.find(itemName => itemName === item);

        return !!(foundItem || foundHeading);
    };

    const disabled = () => {
        let disabled = false;

        requiredItems.forEach(item => {
            if(!user[item]) disabled = true;
        });

        return disabled;
    };

    const submit = (e) => {
        e.preventDefault();
        const newUser = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            address1: user.address1,
            address2: user.address2,
            address3: user.address3,
            address4: user.address4,
            postcode: user.postcode,
            country: user.country,
            dob: user.dob,
            webId: token,
            usertype: user.role,
        };

        // props.saveData(newUser);

        // props.history.push('/registerpdf');

        props.sendRequest(newUser);
    };

    const close = () => {
        setVisible(null);
    };

    // const back = (e) => {
    //     e.preventDefault();
    //     props.history.goBack();
    // };

    const fullName = (firstName, lastName) => {
        let name = '';

        name = name + (firstName ? firstName + ' ' : '');
        name = name + (lastName ? lastName : '');

        return name.toUpperCase();
    };

    const getAddress = (user, addressForm) => {
        const address = [];

        // console.log(user);

        addressForm.forEach((addressItem) => {
            if (user[addressItem.name]) address.push(user[addressItem.name])
        });

        // console.log(address.join(','));

        return address.join(', ');
    };

    const FormDialog = (user, close, visible, visibleValue, formElements) => (
        <DialogBox
            close={() => {
                const userSet = {};

                formElements && formElements.length > 0 && formElements.forEach((formElement) => {
                    const name = formElement.name;
                    const _name = '_' + formElement.name;
                    if (user && (user[name] || user[name] === '')) userSet[_name] = user[name];
                });

                setUser({...user, ...userSet});

                close();
            }}
            visible={visible === visibleValue}
        >
            {formElements && formElements.length > 0 && formElements.map((formElement, i) => {
                const name = '_' + formElement.name;
                return (
                    <Input
                        initialFocus={i === 0}
                        required={required(formElement.name)}
                        key={'fe_' + formElement.name}
                        title={formElement.title}
                        style={{
                            marginLeft: '15px',
                            marginRight: '15px',
                            width: 'calc(100% - 30px)',
                            marginTop: '10px',
                        }}
                        onChange={change}
                        placeholder={formElement.title}
                        name={name}
                        confirm={user && user[name] ? <RiCheckLine size="22" color="green"/> : null}
                        value={user && user[name] ? user[name] : ''}
                    />
                )
            })}
            <Button
                key={'button'}
                style={{
                    marginLeft: '15px',
                    marginRight: '15px',
                    width: 'calc(100% - 30px)',
                    marginTop: '10px',
                }}
                status="primaryThree"
                textStatus="light"
                onClick={() => {
                    const userSet = {};

                    formElements && formElements.length > 0 && formElements.forEach((formElement) => {
                        const name = formElement.name;
                        const _name = '_' + formElement.name;
                        if (user && (user[_name] || user[_name] === '')) userSet[name] = user[_name];
                    });

                    setUser({...user, ...userSet});

                    close();
                }}>Submit</Button>
        </DialogBox>
    );

    const fullNameForm = [
        {
            title: 'FIRST NAME',
            name: 'firstName',
        },
        {
            title: 'LAST NAME',
            name: 'lastName',
        }
    ];

    const addressForm = [
        {
            title: 'STREET 1',
            name: 'address1',
        },
        {
            title: 'STREET 2',
            name: 'address2',
        },
        {
            title: 'CITY',
            name: 'address3',
        },
        {
            title: 'STATE/COUNTY',
            name: 'address4',
        },
        {
            title: 'ZIP/POSTCODE',
            name: 'postcode',
        },
        {
            title: 'COUNTRY',
            name: 'country',
        }
    ];

    return (
        <Background>
            {props.register.error ? <ErrorBox>{props.register.error}</ErrorBox> : null}
            <div style={{maxWidth: 130}}><AppIconLogo src="/assets/images/logos/installer.png"/></div>
            <div style={{marginTop: 20, maxWidth: 400}}><Heading>Welcome to the</Heading></div>
            <div style={{marginTop: 3}}><Heading>Installation Network</Heading></div>
            <div style={{maxWidth: 130}}><PrimaryLogo
                src="/assets/images/logos/zzz-logo-black.png"/></div>

            {!props.register.checkingWebId ? <div style={{width: '100%'}}>
            {props.register.exists ? <>
            {!props.register.requestSent ? <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        paddingLeft: 15,
                        paddingRight: 15
                    }}>
                        <div style={{margin: '25px 0 8px 0'}}><SubHeading>REGISTER</SubHeading></div>
                        {/*<Input*/}
                        {/*    required={required('name')}*/}
                        {/*    title="NAME"*/}
                        {/*    style={{*/}
                        {/*        marginLeft: '25px',*/}
                        {/*        marginRight: '25px',*/}
                        {/*        width: 'calc(100% - 50px)',*/}
                        {/*        marginTop: '10px',*/}
                        {/*        maxWidth: '444px'*/}
                        {/*    }}*/}
                        {/*    onClick={() => {*/}
                        {/*        // props.clearDealers();*/}
                        {/*        // setFocussed(true);*/}
                        {/*        setVisible('name');*/}
                        {/*    }}*/}
                        {/*    placeholder='NAME'*/}
                        {/*    name='name'*/}
                        {/*    confirm={user && user.name ? <RiCheckLine size="22" color="green"/> : null}*/}
                        {/*    value={fullName(user && user.firstName, user && user.lastName)}*/}
                        {/*/>*/}
                        <Input
                            required={required('firstName')}
                            title="FIRST NAME"
                            style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                                width: 'calc(100% - 50px)',
                                marginTop: '10px',
                                maxWidth: '444px'
                            }}
                            onChange={change}
                            placeholder='FIRST NAME'
                            name='firstName'
                            value={user.firstName}
                            confirm={user && user.firstName ? <RiCheckLine size="22" color="green"/> : null}
                        />
                        <Input
                            required={required('lastName')}
                            title="LAST NAME"
                            style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                                width: 'calc(100% - 50px)',
                                marginTop: '10px',
                                maxWidth: '444px'
                            }}
                            onChange={change}
                            placeholder='LAST NAME'
                            name='lastName'
                            value={user.lastName}
                            confirm={user && user.lastName ? <RiCheckLine size="22" color="green"/> : null}
                        />
                        {/*<Input*/}
                        {/*    required={required('email')}*/}
                        {/*    title="EMAIL"*/}
                        {/*    style={{*/}
                        {/*        marginLeft: '25px',*/}
                        {/*        marginRight: '25px',*/}
                        {/*        width: 'calc(100% - 50px)',*/}
                        {/*        marginTop: '10px',*/}
                        {/*        maxWidth: '444px'*/}
                        {/*    }}*/}
                        {/*    onChange={change}*/}
                        {/*    placeholder='EMAIL'*/}
                        {/*    name='email'*/}
                        {/*    value={user.email}*/}
                        {/*    confirm={user && user.email ? <RiCheckLine size="22" color="green"/> : null}*/}
                        {/*/>*/}
                    </div> : null}

                    {!disabled() && props.register.requestSent ? <PassNoteContainer>
                        <PassNote><span style={{fontWeight: 'bold'}}>Request Sent</span><br /><br />
                            You have successfully sent your request to become an Installer.
                        </PassNote>
                        <PassNoteBox style={{textAlign: 'center'}}>THANK YOU</PassNoteBox>
                        <PassNote>
                            You will receive an email once your request has been approved. You may now close this window.
                        </PassNote>
                    </PassNoteContainer> : null}

                    {!disabled() && !props.register.requestSent ? <CompletedFooter>
                        <Button onClick={submit} style={{maxWidth: 444}}>
                            {props.register.sendingRequest ? <Spinner colour="white" width={20} height={20} top={'0px'} />
                                : 'SUBMIT'}
                        </Button>
                    </CompletedFooter> : null}

                </> :
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    paddingLeft: 15,
                    paddingRight: 15
                }}>
                    <div style={{margin: '25px 0 0 0'}}><SubHeading>INVITATION</SubHeading></div>
                    <div style={{margin: '10px 20px'}}>
                        <RegisterNote>
                            <p>To register as an Installer you must first receive an invite from the organisation you
                                will be installing Intelizzz devices for.</p>
                            <p>If you have not received this invitation, please contact them first.</p>
                        </RegisterNote>


                    </div>

                    <FooterBack>
                        <Button onClick={back}>
                            Back
                        </Button>
                    </FooterBack>

                </div>}
            </div> : <motion.span
                style={{
                    marginTop: '20px',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    border: '13px solid lightgray',
                    borderTop: '13px solid dodgerblue'
                }}
                animate={{ rotate: 360 }}
                transition={spinTransition}
            />}
            {FormDialog(user, close, visible, 'name', fullNameForm)}
            {FormDialog(user, close, visible, 'address', addressForm)}
        </Background>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {register: state.register};
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveData: (payload) => dispatch(storeRegistrationData(payload)),
        checkToken: (payload) => dispatch(checkWebId(payload)),
        sendRequest: (payload) => dispatch(sendRequest(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);
