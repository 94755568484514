import React, {useLayoutEffect} from 'react';
import styled from 'styled-components';
import Button from "../../components/button";
import Background from "../../components/background";

const Logo = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;


const PrimaryLogo = styled(Logo)`
    margin-top: 40px;
`;

const SecondaryLogo = styled(Logo)`
    margin-top: 25px;
`;

const AppIconLogo = styled(Logo)`
    margin-top: 45px;
`;

const Heading = styled.span`
  font-size: 2.5em;
  font-weight: 900;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
  text-align: center;
`;

const SubHeading = styled.span`
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
`;

const ForgotPassword = styled.span`
  font-size: 0.7em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
`;

const StartScreen = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    // Curried redirect function
    const navTo = route => (e) => {
        e.preventDefault();
        console.log(props);
        props.history.push('/' + route);
    };

    return (
        <Background>
            <div style={{maxWidth: 200}}><PrimaryLogo
                src="/assets/images/logos/zzz-logo-black.png"/></div>
            <div style={{marginTop: 20, maxWidth: 400}}><Heading>Award winning</Heading></div>
            <div style={{marginTop: 3}}><SubHeading>Subscription-free Asset locator</SubHeading></div>
            <div style={{maxWidth: 150}}><SecondaryLogo src="/assets/images/logos/mib.png"/></div>
            <div style={{maxWidth: 190}}><AppIconLogo src="/assets/images/logos/installer.png"/></div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingLeft: 15,
                paddingRight: 15
            }}>
                <Button onClick={navTo('register')} status="primaryOne" textStatus="light"
                        style={{marginTop: 40, maxWidth: 350}}>REGISTER</Button>
                <Button onClick={navTo('signin')} status="secondaryOne" textStatus="dark" style={{marginTop: 15, maxWidth: 350}}>SIGN IN</Button>
            </div>
            <Button status="invisible" style={{maxWidth: 350}}><ForgotPassword>FORGOT PASSWORD</ForgotPassword></Button>
        </Background>
    )
};

export default StartScreen;
