import {
    AUTH_REQUEST, AUTH_SUCCESS, AUTH_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE
} from "../actionTypes/types";
import Config from '../../config';
import api from '../../utils/api';

const ZZZAPI = process.env.REACT_APP_ZZZAPI;
const PREFIX = Config.prefix;

const authRequest = () => {
    return {
        type: AUTH_REQUEST
    }
};

export const authSuccess = (payload) => {
    return {
        type: AUTH_SUCCESS,
        payload
    }
};

export const authFailure = (payload) => {
    return {
        type: AUTH_FAILURE,
        payload
    }
};

export const authenticate = (payload) => {
    return (dispatch) => {
        dispatch(authRequest());

        const success = (obj) => {
            const userObj = {
                username: obj.username,
                firstName: obj.firstName,
                lastName: obj.lastName
            };

            // console.log('SETTING COOKIE AT authenticate');
            // set local cookie once authenticated
            document.cookie = `zzz${PREFIX}user=` + JSON.stringify(userObj) + '; expires=' + obj.cookieExpDate + '; path=/;';

            dispatch(authSuccess(userObj));
            // dispatch(userGet());
        };

        const failure = (err) => {
            dispatch(authFailure(err))
        };

        return api(
            `${ZZZAPI}/api/authenticate`,
            'POST',
            {
                'prefix': PREFIX
            },
            success,
            failure,
            {
                'password': payload.password,
                'username': payload.username,
                'appName': payload.appName,
                'role': payload.role,
            }
        );
    }
};

export const logoutRequest = () => {
    return {
        type: LOGOUT_REQUEST
    }
};

export const logoutSuccess = (payload) => {
    return {
        type: LOGOUT_SUCCESS,
        payload
    }
};

export const logoutFailure = (payload) => {
    return {
        type: LOGOUT_FAILURE,
        payload
    }
};

export const logout = () => {
    return (dispatch) => {
        dispatch(logoutRequest);

        const success = (payload) => {
            dispatch(logoutSuccess(payload));
            window.location.href = '/';
        };

        const failure = (payload) => {
            dispatch(logoutFailure(payload));
        };

        return api(
            `${ZZZAPI}/api/logout`,
            'GET',
            {
                'prefix': PREFIX
            },
            success,
            failure
        );
    }
};
