import React, {useState} from 'react';
import styled, { css } from 'styled-components';
import getBaseTheme from '../../utils/get-base-theme';

const ButtonBase = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px rgb(205,204,204);
    border-radius: 15px;
    width: 100%;
    font-weight: 500;
    font-size: 1.5em;
    height: auto;
    margin-left: 0;
    padding: 0;
    
    ${props => css`${getBaseTheme(props)}`};
`;

const Tile = ({status, textStatus, style, onClick, children}) => {
    const [pressed, setPressed] = useState(false);

    const onPress = () => {
        if(!pressed) setPressed(true);
    };

    const onRelease = () => {
        if(pressed) setPressed(false);
    };

    return (
        <ButtonBase
            status={status}
            textStatus={textStatus}
            pressed={pressed}
            onClick={onClick}
            onMouseDown={onPress}
            onMouseUp={onRelease}
            onTouchStart={onPress}
            onTouchEnd={onRelease}
            style={{...style}}
        >
            {children}
        </ButtonBase>
    )
};

export default Tile;
