import React, {createRef, useEffect} from 'react';
import {AiFillWarning} from "react-icons/all";
import styled from 'styled-components';

const InputBase = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: solid 1px black;
    border-radius: 8px;
    min-height: 50px;
    width: 100%;
    min-width: 320px;
    background: #ffcf5d;
    flex-wrap: nowrap;
`;

const InputBaseText = styled.input`
    border: 0;
    font-weight: 900;
    font-size: 2.8em;
    flex: 2 1 auto;
    padding: 10px; 
    width: 100%;
    background: transparent;
    text-align: center;
    font-family: UKNumberPlate;
    text-transform:uppercase;
    
    &:focus-visible {
      outline-width: 0;
    }
`;

const ErrorBox = styled.div`
  width: 100%;
  background-color: #F53C5C;
  color: white;
  padding: 4px;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  border-radius: 5px;
`;

const LicensePlateContainer = styled.div`
  padding: 3px;
  border-radius: 8px;
  font-family: UKNumberPlate;
  background: #ffcf5d;
  color: black;  
`;

const SearchIcon = styled.img`
  width: 22px;
`;

const PlateInput = ({onChange, onClick, style, value = '', onFocus, showSubmit, required, error, ...props}) => {
    const textInputRef = createRef();

    const setFocus = () => {
        textInputRef.current.focus();
    };

    useEffect(() => {
        if (onFocus && textInputRef) {
            setFocus();
        }

    }, [onFocus, textInputRef, setFocus]);

    return (
        <>
            {error ? <ErrorBox>
                <AiFillWarning size={35} style={{marginRight: 5}}/>{error}. Please check and try again.
            </ErrorBox> : null}
            <LicensePlateContainer>
                <InputBase
                    style={style}
                    onClick={setFocus}
                >
                    <InputBaseText
                        ref={textInputRef}
                        value={value || ''}
                        onChange={onChange} {...props}
                        placeholder="FIND NUMBER"
                    />
                    {showSubmit ? <div style={{
                        // opacity: '0.9',
                        border: 'solid 2px black',
                        background: '#ffbc61',
                        minWidth: 40,
                        minHeight: 40,
                        borderRadius: '50%',
                        marginRight: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                        onClick={onClick}
                    ><SearchIcon src={'/assets/images/shapes/search-dark-icon.svg'}/></div> : null}
                </InputBase>
            </LicensePlateContainer>
        </>
    )
};

export default PlateInput;
