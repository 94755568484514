import React from 'react';
import {motion} from "framer-motion";

const spinTransition = {
    loop: Infinity,
    ease: "linear",
    duration: 1
};

const Spinner = (props) => {
    return (
        <div style={{height: props.height, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
        <motion.span
            style={{
                marginTop: props.top || '20px',
                width: props.width || '50px',
                height: props.height || '50px',
                borderRadius: '50%',
                border: `5px solid ${'transparent'}`,
                borderTop: `5px solid ${props.colour || 'dodgerblue'}`,
                borderBottom: `5px solid ${props.colour || 'dodgerblue'}`,
                borderLeft: `5px solid ${props.colour || 'dodgerblue'}`
            }}
            animate={{ rotate: 360 }}
            transition={spinTransition}
        />
        </div>
    );
};

export default Spinner;
