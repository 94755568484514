import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {motion} from 'framer-motion';
import getBaseTheme from '../../utils/get-base-theme';

const CategoriesContainer = styled.div`
  position: relative;
  margin-top: 10px;
  //border: solid black 1px;
  flex: 1;
  width: 100%;
  padding: 4px;
  background: #282c34;
  color: white;
  border-radius: 6px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  
  ${props => css`${getBaseTheme(props)}`};
`;

const CategoriesSelectorComponent = styled.div`
  width: 100%;
  flex: 1;
  background: #000;
  position: absolute;
  ${props => css`${getBaseTheme(props)}`};
  top: -500px;
  bottom: 0;
  border-radius: 15px;
`;
const GroupCategoryText = styled.div`
    text-align: center;
    font-size: 0.6em;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
    padding: 5px;
`;

const CategoryText = styled.div`
    text-align: center;
    font-size: 1.1em;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
`;

const SiteNameText = styled.div`
    text-align: center;
    font-size: 1.1em;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    //padding-right: 5px;
`;

const TextContainer = styled.div`
  border-right: ${props => props.bordered ? '1px white solid' : 'none'};
  width: 100%;
`;

const CategoriesSelectorInternal = styled.div`
  min-height: 100%;
  bottom: 0;
  flex: 1;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 10px;
`;

const OkayButton = styled.div`
  width: 100%;
  text-align: center;
  padding: 15px;
`;

const CategoriesSelectorBox = styled.div`
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  //border: solid white 1px;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  display: flex;
  justify-content: space-around;
  padding: 10px 10px;
`;

// const Category = styled(motion.div)`
//   border: 1px solid white;
//   border-radius: 8px;
//   flex: 1;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100px;
//   max-height: 100px;
//   max-width:  110px;
//   min-width:  110px;
//   font-size: 1.8em;
//   padding: 2px;
//   margin: 10px 10px;
// `;

const Category = styled(motion.div)`
  border: 1px solid white;
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100px;
  max-height: 100px;  
  max-width:  120px;
  min-width:  120px;
  font-size: 1.1em;
  padding: 2px;
  margin: 5px 5px;
`;

const CategorySelected = styled(motion.div)`
  border: 1px solid white;
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100px;
  max-height: 100px;  
  max-width:  120px;
  min-width:  120px;
  font-size: 1.1em;
  padding: 2px;
  margin: 5px 5px;
  background: rgb(58, 170, 54); 
  font-weight: bold;
`;

const CategoriesSelectorTitle = styled.div`
    top: 0;
    //padding-top: 30px;
    //padding-bottom: 20px;
    border-bottom: 1px solid white;
    width: 100%;
    text-align: center;
    font-weight: 600;
`;

const VehicleIcon = styled.img`
  max-width: 100px;
`;

const Disabled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: inherit;
    background: white;
    opacity: 0.6;
`;

// const categoriesList = ['SUV', 'PDI', 'LOT 8', 'BLUE'];

const CategoriesSelector = ({status, textStatus, categoriesList, selected, setSelected, onOkay}) => {
    const _categoriesList = categoriesList.map((category, index) =>
    index === selected ? <CategorySelected
        key={'cat' + index}
        selected={index === selected}
        onClick={() => setSelected(index)}>
            <VehicleIcon src={`/assets/images/shapes/${category.toLowerCase()}-icon.png`} />
            {category}
    </CategorySelected> :
        <Category
            key={'cat' + index}
            selected={index === selected}
            onClick={() => setSelected(index)}>
            <VehicleIcon src={`/assets/images/shapes/${category.toLowerCase()}-icon.png`} />
            {category}
        </Category>);

    return (
        <CategoriesSelectorComponent status={status}>
            <CategoriesSelectorInternal>
                <CategoriesSelectorTitle>GROUP CATEGORY</CategoriesSelectorTitle>
                <div style={{fontSize: '0.8em', padding: 20}}>Please choose...</div>
                <CategoriesSelectorBox>
                    {_categoriesList}
                </CategoriesSelectorBox>
                <OkayButton onClick={onOkay}>OK</OkayButton>
            </CategoriesSelectorInternal>
        </CategoriesSelectorComponent>
    );
};

const Categories = ({
                        status,
                        textStatus,
                        siteName = '',
                        categoriesList = [],
                        selected,
                        setSelected,
                        selectedName,
                        disabled
                    }) => {
    const [showSelector, setShowSelector] = useState(false);
    const [_categoriesList, setCategoriesList] = useState([]);

    useEffect(() => {
        if(_categoriesList.length < 1 && (categoriesList && categoriesList.length > 0)) {
            setCategoriesList(categoriesList);
        }
    }, [categoriesList, setCategoriesList]);

    return (
        <CategoriesContainer status={status} textStatus={textStatus}>
            {showSelector ? <CategoriesSelector
                status={status}
                onOkay={() => setShowSelector(!showSelector)}
                categoriesList={_categoriesList}
                selected={selected}
                setSelected={setSelected}
            /> : null}
            {disabled ? <Disabled /> : null}
            <TextContainer bordered={true}>
                <GroupCategoryText>GROUP CATEGORY</GroupCategoryText>
            </TextContainer>
            <TextContainer
                bordered={true}
                onClick={() => setShowSelector(!showSelector)}
            >
                <CategoryText>{selectedName || 'Select...'}</CategoryText>
            </TextContainer>
            <TextContainer>
                <SiteNameText>{siteName}</SiteNameText>
            </TextContainer>
        </CategoriesContainer>);
};

export default Categories;
