import React from 'react';
import styled from 'styled-components';
import Tile from '../tile';

const ContainerDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 130px;
`;

const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  width: 100%;
  min-height: 100%;
  flex: 1;
  font-size: ${props => props.size === 'large' ? '4em' : '1.2em'};
  font-weight: 200;
  padding-top: 5px;
`;

const SubtitleDiv = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  font-size: 0.6em;
  padding: 0 0 10px 0;
`;

const BasicTile = ({status, textStatus, content, subtitle, size, onClick, style}) => {
    return (<Tile
                status={status}
                textStatus={textStatus}
                onClick={onClick}
                style={{...style}}
            >
                <ContainerDiv>
                    <ContentDiv size={size}>{content}</ContentDiv>
                    <SubtitleDiv>{subtitle}</SubtitleDiv>
                </ContainerDiv>
    </Tile>);
};

export default BasicTile;
