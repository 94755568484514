import {
    GET_DEALERS_REQUEST,
    GET_DEALERS_FAILURE,
    GET_DEALERS_SUCCESS,
    LOGOUT_SUCCESS,
    CLEAR_DEALERS_REQUEST,
    GET_SITES_REQUEST,
    GET_SITES_SUCCESS,
    GET_SITES_FAILURE,
    SET_DEALER, CLEAR_ERRORS
} from "../actionTypes/types";

const initialState = {
    isLoadingDealers: false,
    isLoadingSites: false,
    dealers: [],
    dealer: null,
    site: null,
    sites: [],
    error: null,
};

const DealersReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_DEALERS_REQUEST:
            return {
                ...state,
                isLoadingDealers: true,
                error: null
            };
        case GET_DEALERS_SUCCESS:
            return {
                ...state,
                dealers: payload.result,
                isLoadingDealers: false,
                error: null
            };
        case GET_DEALERS_FAILURE:
            return {
                ...state,
                error: payload.message,
                isLoadingDealers: false,
            };
        case GET_SITES_REQUEST:
            return {
                ...state,
                isLoadingSites: true,
                error: null
            };
        case GET_SITES_SUCCESS:
            return {
                ...state,
                sites: payload.result,
                isLoadingSites: false,
                error: null
            };
        case GET_SITES_FAILURE:
            return {
                ...state,
                error: payload.message,
                isLoadingSites: false,
            };
        case SET_DEALER:
            return {
                ...state,
                dealer: payload.dealer,
                site: payload.site
            };
        case LOGOUT_SUCCESS:
            return initialState;
        case CLEAR_DEALERS_REQUEST:
            return {
                ...state,
                dealers: [],
                sites: [],
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};

export default DealersReducer;

