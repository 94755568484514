import React, {useState, useEffect, useLayoutEffect} from 'react';
import styled, {withTheme} from 'styled-components';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {RiCheckLine, RiArrowRightLine, RiArrowLeftLine} from 'react-icons/ri'
import {useDexie, useDexiePutItem} from 'use-dexie';
import Button from "../../components/button";
import Input from "../../components/input";
import Background from "../../components/background";
import {clearDealers, getDealers, getSites, setDealer} from "../../redux/actions/dealers";
import {clearErrors} from "../../redux/actions/shared";
import DialogBox from "../../components/dialogbox";
import ErrorBox from "../../components/errorbox";
import Config from '../../config';
import Cookies from "../../utils/cookies";
import {logout} from "../../redux/actions/auth";

const PREFIX = Config.prefix;

const Logo = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

const Hexagon = styled.img`
  width: 50px;
`;

const PrimaryLogo = styled(Logo)`
    margin-top: 40px;
`;

const AppIconLogo = styled(Logo)`
    margin-top: 45px;
`;

const Heading = styled.span`
  font-size: 1.8em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
  text-align: center;
`;

const SubHeading = styled.span`
  font-size: 1em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
    //border: solid 1px blue;
    //min-width: 320px !important;
    padding: 0 10px 0 50px;
    flex: 1;
`;

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
`;

const Result = styled.div`
    width: 100%;
    text-align: left;
    font-size: 0.8em;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 10px 20px;
`;

const ResultHeading = styled.span`
  font-weight: bold;
`;

const AddSite = (data) => {
    // await zzzLocalStorage.dealers.add({ dealer: data.dealer, site: data.site }, 1);
    useDexie('ZZZ_DB', {dealers: `id, dealer, site`}, (db) => {
        db.dealers.put({dealer: data.dealer, site: data.site});
    })
};

const SiteScreen = (props) => {
    let [site, setSite] = useState(null);
    let [dealers, setDealers] = useState(null);
    let [searched, setSearched] = useState(false);
    let [searchModal, setSearchModal] = useState(false);
    let [selected, setSelected] = useState(null);
    let [getSites, setGetSites] = useState(false);
    let [focussed, setFocussed] = useState(false);

    useDexie('ZZZ_DB', {dealers: `id, dealer, site`}, (db) => {
        db.dealers.put({id: 1, dealer: null, site: null});
    });

    const putTask = useDexiePutItem('dealers');

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        props.getDealers();
    }, [props.getDealers]);

    useEffect(() => {
        if (getSites && selected && selected._id) {
            setGetSites(false);
            props.getSites(selected._id);
            setSite({...site, site: null});
        }

        if (searchModal && focussed) {
            setFocussed(false);
        }

    }, [getSites, setGetSites, props.getSites, selected, props.dealers.sites, setSite]);

    // Curried redirect function
    const navTo = route => (e) => {
        e.preventDefault();
        // console.log(props);
        props.history.push('/' + route);
    };

    const selectSite = async () => {
        if ((site && site.site) && selected) {
            // AddSite({dealer: selected, site: site.site});
            putTask({id: 1, dealer: selected, site: site.site});
            props.setDealer({site: site.site, dealer: selected});
            // props.clearDealers();
            props.history.push('/device');
        }
    };

    const change = (e) => {
        setSite({...site, [e.target.name]: e.target.value})
    };

    const pick = (e) => {
        const _site = props.dealers.sites.find((site) => site._id === e.target.value);
        setSite({...site, site: _site});
    };

    const pickDealer = (e) => {
        const _dealer = props.dealers.dealers.find((dealer) => dealer._id === e.target.value);
        setSelected(_dealer);
        setGetSites(true);
    }

    const searchDealers = () => {
        if (site && site.search) {
            setSearched(true);
            props.getDealers(site.search);
        }
    };

    const close = () => {
        setSearched(false);
        setSearchModal(false);
        setFocussed(false);
    };

    const SearchResult = ({name, address}) => {
        return (
            <Result>
                <ResultHeading>{name}</ResultHeading>
                <span>{address}</span>
            </Result>
        )
    };

    const logout = (e) => {
        e.preventDefault();
        Cookies.delete(`zzz${PREFIX}user`);
        Cookies.delete('zzz' + PREFIX);
        props.logout();
    };

    // const back = (e) => {
    //     e.preventDefault();
    //     props.history.goBack();
    // };

    return (
        <Background>
            {props.dealers.error ? <ErrorBox close={props.clearErrors}>{props.dealers.error}</ErrorBox> : null}
            {/*<pre>{JSON.stringify(props.dealers, null, 2)}</pre>*/}
            <div style={{maxWidth: props.theme.logos.primary.width}}>
                <AppIconLogo src={props.theme.logos.primary.image}/>
            </div>
            <div style={{marginTop: 20, maxWidth: 400}}>
                <Heading>Welcome to {Config.private ? 'your' : 'the'}</Heading>
            </div>
            <div style={{marginTop: 3}}>
                <Heading>Installation Network</Heading>
            </div>
            <div style={{maxWidth: 130}}>
                <PrimaryLogo/>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingLeft: 15,
                paddingRight: 15
            }}>
                <div style={{margin: '25px 0 8px 0'}}>
                    <SubHeading>CHOOSE SITE</SubHeading>
                    {/*<pre>{JSON.stringify(site, null, 2)}</pre>*/}
                </div>
                {/*<Input*/}
                {/*    title="DISTRICT"*/}
                {/*    style={{*/}
                {/*        marginLeft: '25px',*/}
                {/*        marginRight: '25px',*/}
                {/*        width: 'calc(100% - 50px)',*/}
                {/*        marginTop: '10px'*/}
                {/*    }}*/}
                {/*    onClick={() => {*/}
                {/*        props.clearDealers();*/}
                {/*        setFocussed(true);*/}
                {/*        setSearchModal(true);*/}
                {/*    }}*/}
                {/*    placeholder='DISTRICT'*/}
                {/*    name='dealer'*/}
                {/*    readOnly*/}
                {/*    value={(selected && selected.name.toUpperCase()) || ''}*/}
                {/*    confirm={selected ? <RiCheckLine size="22" color="green"/> : null}*/}
                {/*/>*/}
                <Input
                    title="BRANCH"
                    style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                        paddingRight: '-25px',
                        width: 'calc(100% - 50px)',
                        marginTop: '10px'
                    }}
                    onChange={pickDealer}
                    placeholder='DISTRICT'
                    name='dealer'
                    items={props.dealers.dealers}
                    value={(selected && selected._id) || ''}
                />
                <Input
                    title="SITE NO."
                    style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                        paddingRight: '-25px',
                        width: 'calc(100% - 50px)',
                        marginTop: '10px'
                    }}
                    onChange={pick}
                    placeholder='SITE NO.'
                    name='site'
                    items={props.dealers.sites}
                    value={(site && site.site && site.site._id) || ''}
                    confirm={site && site.site ? <RiCheckLine size="22" color="green"/> : null}
                />
            </div>

            <div style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'flex-end',
                paddingBottom: 20
            }}>

                <Footer>
                    <Button
                        onClick={logout}
                        status="primaryThree"
                        textStatus="light"
                        style={{
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            display: 'flex',
                            fontSize: '0.8em',
                            fontWeight: 100,
                            marginLeft: -30,
                        }}>
                        <RiArrowLeftLine size="30" style={{flex: 1}} /><ButtonText>SIGN OUT</ButtonText><div style={{flex: 1, marginRight: -10}}>&nbsp;</div>
                    </Button>

                    <Button
                        status="invisible"
                        style={{
                            // width: 55,
                            // display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            fontSize: '0.8em',
                            fontWeight: 100,
                        }}>
                        Continue
                        <div
                            style={{
                                width: 55,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingLeft: 8,
                            }}>
                            <RiArrowRightLine
                                style={{
                                    position: 'absolute',
                                    color: 'rgb(255,255,255)'
                                }}
                                size="30"
                                onClick={selectSite}
                                color="red"/>
                            <Hexagon src='/assets/images/shapes/hexagonal.svg'/>
                        </div>
                    </Button>
                </Footer>
            </div>

            <DialogBox
                close={close}
                visible={searchModal}
            >
                <Input
                    title="SEARCH"
                    style={{
                        marginLeft: '15px',
                        marginRight: '15px',
                        width: 'calc(100% - 30px)',
                        marginTop: '5px',
                    }}
                    onChange={change}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            searchDealers();
                        }
                    }}
                    placeholder='SEARCH'
                    name='search'
                    onFocus={true}
                />
                <Button
                    style={{
                        marginLeft: '15px',
                        marginRight: '15px',
                        width: 'calc(100% - 30px)',
                        marginTop: '10px',
                    }}
                    status="primaryThree"
                    textStatus="light"
                    onClick={searchDealers}>Search</Button>
                {props.dealers.dealers.length > 0 ?
                    <div>
                        {props.dealers.dealers.map((dealer, index) => {
                            return (
                                <div
                                    key={'search' + index}
                                    onClick={() => {
                                        setSelected(dealer);
                                        setGetSites(true);
                                        close();
                                    }}>
                                    <SearchResult
                                        name={dealer.name}
                                        address={dealer.address}/>
                                </div>)
                        })}</div>
                    : null}

                {!props.dealers.isLoadingDealers && searched && props.dealers.dealers.length === 0 ?
                    <p style={{textAlign: 'center'}}>No results.</p>
                    : null}
            </DialogBox>
        </Background>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {auth: state.auth, dealers: state.dealers};
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDealers: (payload) => dispatch(getDealers(payload)),
        getSites: (payload) => dispatch(getSites(payload)),
        setDealer: (payload) => dispatch(setDealer(payload)),
        clearDealers: () => dispatch(clearDealers()),
        clearErrors: () => dispatch(clearErrors()),
        logout: () => dispatch(logout()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SiteScreen));
