
const getBaseTheme = (props) => {
    return `
        background: ${props.status ? props.theme[props.status] : 'black'};
        color: ${props.textStatus ? props.theme.text[props.textStatus] : 'rgb(255,255,255)'};
        opacity: ${props.pressed ? '0.8' : '1'};
    `
};

export default getBaseTheme;
