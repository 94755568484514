export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const simplifyId = (id) => {
    if (!id || (id && id.length < 10)) {
        return id;
    }
    const newId =
        id.slice(id.length - 10, id.length - 5) +
        '-' +
        id.slice(id.length - 5, id.length);

    return newId;
};

export const validDeviceId = (id) => {
    if(!id || id.length !== 19) return false;
    const re = /^-?\d+\.?\d*$/;
    return re.test(String(id));
};

export const validInstallationCode = (id) => {
    if(!id || id.length !== 9) return false;
    const re = /^[0-9]+(-[0-9]+)+$/;
    return re.test(String(id));
};

export const passwordValid = (pwd) => {
    const match = /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/
    return pwd ? !pwd.search(match) : false;
};
