import React, {useLayoutEffect} from 'react';
import styled from 'styled-components';
import {withRouter, Redirect} from 'react-router-dom';
import {RiAddLine, RiSubtractLine, RiArrowRightLine, RiRefreshLine} from 'react-icons/ri'
import { MdRefresh } from 'react-icons/md';
import {logout} from "../../redux/actions/auth";
import Button from "../../components/button";
import BasicTile from "../../components/basic-tile";
import Cookies from '../../utils/cookies';
import Background from "../../components/background";
import {connect} from "react-redux";
import Config from '../../config';
import GetLogo from "../../components/getlogo";

const PREFIX = Config.prefix;
const VERSION = Config.version;

const Heading = styled.span`
  font-size: 1.9em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.text.main || 'blue'};
  text-align: center;
`;

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.4em;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
`;

const TopRow = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //border: solid orange 2px;
  width: 100%;
  min-height: 40px;
  padding: 10px 15px;
`;

const ProfileIcon = styled.img`
  width: 34px;
`;

const DeviceManagementScreen = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

// Curried redirect function
    const navTo = route => (e) => {
        e.preventDefault();
        // console.log(props);
        props.history.push('/' + route);
    };

    const back = (e) => {
        e.preventDefault();
        props.history.goBack();
    };

    const logout = (e) => {
        e.preventDefault();
        Cookies.delete(`zzz${PREFIX}user`);
        Cookies.delete('zzz' + PREFIX);
        props.logout();
    };

    return (
        <Background>
            <div style={{position: 'absolute', width: 80, left: 0}}><Button
                style={{textAlign: 'left', paddingTop: 15, paddingLeft: 15}}
                status="invisible" onClick={() => window.location.reload()}><MdRefresh color='orange' size={40} /></Button>
            </div>
            <TopRow>
                <ProfileIcon src='/assets/images/shapes/profile-icon.svg'/>
            </TopRow>
            {props.dealers.dealer ? <GetLogo logoData={props.dealers.dealer}/> : null}
            <div style={{maxWidth: 400, marginTop: 10}}>
                <Heading>{props.dealers.site && props.dealers.site.name ? props.dealers.site.name : ''}</Heading></div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingLeft: 15,
                paddingRight: 15,
                flex: 1,
            }}>
                <BasicTile style={{marginTop: 20}}
                           onClick={navTo('install/add')}
                           content={<RiAddLine/>} subtitle="ADD DEVICE" status="primaryTwo" textStatus="light"
                           size="large"/>
                <BasicTile style={{marginTop: 10}}
                           onClick={navTo('install/remove')}
                           content={<RiSubtractLine/>} subtitle="REMOVE DEVICE" status="primaryThree" textStatus="light"
                           size="large"/>
                <BasicTile style={{marginTop: 10}}
                           onClick={back}
                           content="CHANGE SITE" status="secondaryTwo" textStatus="dark"/>
                <div style={{display: 'flex', flex: 1, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'flex-end', paddingBottom: 40}}>
                    <Button onClick={logout} status="primaryThree" textStatus="light"
                            style={{marginTop: 60, maxHeight: 10, display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{flex: 1}}>&nbsp;</div><ButtonText>SIGN OUT</ButtonText><RiArrowRightLine size="30" style={{flex: 1}} />
                    </Button>
                </div>
            </div>
            <div style={{
                bottom: 2,
                position: 'absolute',
                fontSize: '0.8em',
                right: 5,
                color: 'rgb(140, 140, 140)'
            }}>Version {VERSION}</div>
        </Background>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        dealers: state.dealers,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeviceManagementScreen));
