import React, {useState} from 'react';
import styled, { css } from 'styled-components';
import getBaseTheme from '../../utils/get-base-theme';

const BaseButton = styled.button`
    border: none;
    border-radius: 8px;
    min-height: 40px;
    width: 100%;
    font-weight: 800;
    font-size: 0.9em;
    
    ${props => css`${getBaseTheme(props)}`};
    
    opacity: ${props => props.disabled ? 0 : 1};
`;

const Button = ({status, textStatus, onClick, style, disabled, children}) => {
    const [pressed, setPressed] = useState(false);

    const onPress = () => {
        if(!pressed) setPressed(true);
    };

    const onRelease = () => {
        if(pressed) setPressed(false);
    };

    return (
        <BaseButton
            disabled={disabled}
            status={status}
            textStatus={textStatus}
            pressed={pressed}
            onClick={onClick}
            onMouseDown={onPress}
            onMouseUp={onRelease}
            onTouchStart={onPress}
            onTouchEnd={onRelease}
            style={{...style}}
        >
            {children}
        </BaseButton>
    )
};

export default Button;
