import {
    INSTALL_DEVICE_REQUEST,
    INSTALL_DEVICE_SUCCESS,
    INSTALL_DEVICE_FAILURE,
    REMOVE_DEVICE_REQUEST,
    REMOVE_DEVICE_SUCCESS,
    REMOVE_DEVICE_FAILURE,
    CLEAR_DEVICE_STATUS,
    GET_DEALERS_SUCCESS,
    GET_DEALERS_FAILURE,
    GET_VINDATA_REQUEST,
    GET_VINDATA_SUCCESS,
    GET_VINDATA_FAILURE,
    GET_INSTALLDATA_REQUEST,
    GET_INSTALLDATA_SUCCESS, GET_INSTALLDATA_FAILURE,
} from "../actionTypes/types";
import api from '../../utils/api';
import Config from '../../config'

const ZZZAPI = process.env.REACT_APP_ZZZAPI;
const PREFIX = Config.prefix;

const installRequest = () => {
    return {
        type: INSTALL_DEVICE_REQUEST
    }
};

export const installSuccess = (payload) => {
    return {
        type: INSTALL_DEVICE_SUCCESS,
        payload
    }
};

export const installFailure = (payload) => {
    return {
        type: INSTALL_DEVICE_FAILURE,
        payload
    }
};

export const installDevice = (payload) => {
    return (dispatch) => {
        dispatch(installRequest());

        const success = (result) => {
            dispatch(installSuccess(result));
        };

        const failure = (err) => {
            dispatch(installFailure(err))
        };

        return api(
            `${ZZZAPI}/api/devices/installer/install`,
            'PUT',
            {
                'prefix': PREFIX
            },
            success,
            failure,
            payload
        );
    }
};

const removeRequest = () => {
    return {
        type: REMOVE_DEVICE_REQUEST
    }
};

export const removeSuccess = (payload) => {
    return {
        type: REMOVE_DEVICE_SUCCESS,
        payload
    }
};

export const removeFailure = (payload) => {
    return {
        type: REMOVE_DEVICE_FAILURE,
        payload
    }
};

export const removeDevice = (payload) => {
    return (dispatch) => {
        dispatch(removeRequest());

        const success = (result) => {
            dispatch(removeSuccess(result));
        };

        const failure = (err) => {
            dispatch(removeFailure(err))
        };

        return api(
            `${ZZZAPI}/api/devices/installer/uninstall`,
            'PUT',
            {
                'prefix': PREFIX,
                'internal-api-version': 2
            },
            success,
            failure,
            payload
        );
    }
};

export const getVinDataRequest = () => {
    return {
        type: GET_VINDATA_REQUEST,
    }
};

export const getVinDataSuccess = (payload) => {
    return {
        type: GET_VINDATA_SUCCESS,
        payload
    }
};

export const getVinDataFailure = (payload) => {
    return {
        type: GET_VINDATA_FAILURE,
        payload
    }
};

export const getVinData = (payload) => {
    return (dispatch) => {
        dispatch(getVinDataRequest());

        const success = (result) => {
            dispatch(getVinDataSuccess(result));
        };

        const failure = (err) => {
            dispatch(getVinDataFailure(err))
        };

        return api(
            `${ZZZAPI}/api/devices/installer/getvindata?group=${payload.groupId}&site=${payload.siteId}&vin=${payload.vin}`,
            'GET',
            {
                'prefix': PREFIX
            },
            success,
            failure,
        );
    }
};

export const getRegData = (payload) => {
    return (dispatch) => {
        dispatch(getVinDataRequest());

        const success = (result) => {
            dispatch(getVinDataSuccess(result));
        };

        const failure = (err) => {
            dispatch(getVinDataFailure(err))
        };

        return api(
            `${ZZZAPI}/api/devices/installer/getvindata?group=${payload.groupId}&site=${payload.siteId}&plate=${payload.plate}`,
            'GET',
            {
                'prefix': PREFIX
            },
            success,
            failure,
        );
    }
};

export const getInstallDataRequest = () => {
    return {
        type: GET_INSTALLDATA_REQUEST,
    }
};

export const getInstallDataSuccess = (payload) => {
    return {
        type: GET_INSTALLDATA_SUCCESS,
        payload
    }
};

export const getInstallDataFailure = (payload) => {
    return {
        type: GET_INSTALLDATA_FAILURE,
        payload
    }
};

export const getInstallData = (payload) => {
    return (dispatch) => {
        dispatch(getInstallDataRequest());

        const success = (result) => {
            dispatch(getInstallDataSuccess(result));
        };

        const failure = (err) => {
            dispatch(getInstallDataFailure(err))
        };

        let parameters = '';
        if(payload.installCode) parameters += `&code=${payload.installCode}`;
        if(payload.plate) parameters += `&plate=${payload.plate}`;

        return api(
            `${ZZZAPI}/api/devices/installer/getinstalldata?group=${payload.groupId}&site=${payload.siteId}${parameters}`,
            'GET',
            {
                'prefix': PREFIX
            },
            success,
            failure,
        );
    }
};

export const clearDeviceStatus = () => {
    return {
        type: CLEAR_DEVICE_STATUS
    }
};


