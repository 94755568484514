import {
    CHECK_WEB_ID_FAILURE,
    CHECK_WEB_ID_REQUEST,
    CHECK_WEB_ID_SUCCESS,
    INSTALL_DEVICE_FAILURE,
    INSTALL_DEVICE_REQUEST,
    INSTALL_DEVICE_SUCCESS, SEND_REQUEST_FAILURE,
    SEND_REQUEST_REQUEST, SEND_REQUEST_SUCCESS,
    STORE_USER_DATA
} from "../actionTypes/types";
import api from '../../utils/api';
import Config from '../../config'

const ZZZAPI = process.env.REACT_APP_ZZZAPI;
const PREFIX = Config.prefix;

export const storeRegistrationData = (payload) => {
    return {
        type: STORE_USER_DATA,
        payload
    }
};

const checkWebIdRequest = () => {
    return {
        type: CHECK_WEB_ID_REQUEST
    }
};

const checkWebIdSuccess = (payload) => {
    return {
        type: CHECK_WEB_ID_SUCCESS,
        payload
    }
};

const checkWebIdFailure = (payload) => {
    return {
        type: CHECK_WEB_ID_FAILURE,
        payload
    }
};

export const checkWebId = (payload) => {
    return (dispatch) => {
        dispatch(checkWebIdRequest());

        const success = (result) => {
            dispatch(checkWebIdSuccess(result));
        };

        const failure = (err) => {
            dispatch(checkWebIdFailure(err))
        };

        return api(
            `${ZZZAPI}/api/installer/webid?webid=${payload}`,
            'GET',
            {
                'prefix': PREFIX
            },
            success,
            failure,
        );
    }
};

const sendRequestRequest = () => {
    return {
        type: SEND_REQUEST_REQUEST
    }
};

export const sendRequestSuccess = (payload) => {
    return {
        type: SEND_REQUEST_SUCCESS,
        payload
    }
};

export const sendRequestFailure = (payload) => {
    return {
        type: SEND_REQUEST_FAILURE,
        payload
    }
};

export const sendRequest = (payload) => {
    return (dispatch) => {
        dispatch(sendRequestRequest());

        const success = (result) => {
            dispatch(sendRequestSuccess(result));
        };

        const failure = (err) => {
            dispatch(sendRequestFailure(err))
        };

        return api(
            `${ZZZAPI}/api/installer/register`,
            'POST',
            {
                'prefix': PREFIX
            },
            success,
            failure,
            payload
        );
    }
};
