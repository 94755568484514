import React, {createRef, useEffect, useState} from 'react';
import styled from 'styled-components';

const InputBase = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: solid 2px black;
    border-radius: 8px;
    min-height: 50px;
    width: 100%;
    min-width: 320px;
    background: rgb(255, 255, 255);
    flex-wrap: nowrap;
`;

const InputBaseText = styled.input`
    border: 0;
    font-weight: 900;
    font-size: 0.9em;
    flex: 2 1 auto;
    padding: .5em 1em; 
    width: 100%;
    
    &:focus-visible {
      outline-width: 0;
    }
`;

const InputSelect = styled.select`
    width: 100%;
    font-weight: 900;
    font-size: 0.9em;
    border: none;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: .5em 1em; 
    
    &:focus-visible {
      outline-width: 0;
    }    
`;

const Input = ({title, confirm, onChange, style, items = [], value, onFocus, initialFocus, ...props}) => {
    let [initial, setInitial] = useState(initialFocus);

    const textInputRef = createRef();

    const setFocus = () => {
        textInputRef.current.focus();
    };

    const generateSelect = (items) => {
        if (!items) return;
        return items.map((item, index) => <option key={'siteselect-' + index} value={item._id}>{item.name}</option>)
    };

    const deselected = <option key={'siteselect-'} value="">Choose...</option>;

    useEffect(() => {
        if(onFocus && textInputRef) {
            setFocus();
        }

        if(initial) {
            setFocus();
            setInitial(false);
        }
    }, [onFocus, textInputRef, setFocus, initial, setInitial]);

    return (
        <InputBase
            style={style}
            onClick={props.onClick || setFocus}
        >
            <div style={{paddingLeft: 10, minWidth: 120}}>{title}</div>

            {items && items.length > 0 ?
                <InputSelect
                    ref={textInputRef}
                    value={value}
                    onChange={onChange}
                >
                    {[deselected, ...generateSelect(items)]}
                </InputSelect> :
                <InputBaseText
                    ref={textInputRef}
                    value={value}
                    onChange={onChange} {...props}
                />}
            {confirm ?
                <div
                    style={{
                        paddingRight: 10,
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end'
                    }}
                >{confirm}</div> : null}
        </InputBase>
    )
};

export default Input;
