import React, {useState} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {RiArrowRightLine} from 'react-icons/ri'
import Background from "../../components/background";
import Button from '../../components/button';
import {sendRequest, storeRegistrationData} from "../../redux/actions/register";
import Spinner from "../../components/spinner";

const quiz = [
    {
        type: 'answers',
        question: 'Is Intelizzz...',
        answers: [
            {answer: 'Wired'},
            {answer: 'Wireless', correct: true}
        ],
        image: {
            uri: '',
            label: 'No Wires'
        },
        correct: 'Intelizzz is a wireless device.',
        incorrect: 'Intelizzz is a wireless device.'
    },
    {
        type: 'answers',
        question: 'When installing Intelizzz, is it important which way up the device is mounted?',
        answers: [
            {answer: 'YES', correct: true},
            {answer: 'NO'}
        ],
        image: {
            uri: '',
            label: 'No Wires'
        },
        correct: 'Intelizzz’s built in GPS Antenna is mounted on the curved side, so this would need to be mounted face down or on its side.',
        incorrect: 'Intelizzz’s built in GPS Antenna is mounted on the curved side, so this would need to be mounted face down or on its side.'
    },
    {
        type: 'answers',
        question: 'Is it important to mount Intelizzz on a flat surface?',
        answers: [
            {answer: 'YES', correct: true},
            {answer: 'NO'}
        ],
        image: {
            uri: '',
            label: 'No Wires'
        },
        correct: 'It is very important to ensure Intelizzz is mounted on a flat surface with the light sensor completely covered so light cannot pass over the sensor.',
        incorrect: 'It is very important to ensure Intelizzz is mounted on a flat surface with the light sensor completely covered so light cannot pass over the sensor.'
    },
    {
        type: 'images',
        question: <span>Click on the following images where you would <span style={{color: 'red', fontWeight: 'bold'}}>NOT</span> install Intelizzz...</span>,
        images: [
            { uri: '', correct: true},
            { uri: ''},
            { uri: ''},
            { uri: ''},
        ],
        correct: 'Well done !! You are right !',
        incorrect: 'Oh no, you have a wrong answer please try again.'
    },
    {
        type: 'images',
        question: <span>Click on the following images where you <span style={{color: 'rgb(58, 170, 53)', fontWeight: 'bold'}}>WOULD</span> install Intelizzz...</span>,
        images: [
            { uri: ''},
            { uri: '', correct: true},
            { uri: ''},
            { uri: ''},
        ],
        correct: 'Well done !! You are right !',
        incorrect: 'Oh no, you have a wrong answer please try again.'
    },
    {
        type: 'images',
        question: <span>Click on the following images that show installation <span style={{color: 'rgb(58, 170, 53)', fontWeight: 'bold'}}>correctly</span>...</span>,
        images: [
            { uri: ''},
            { uri: ''},
            { uri: '', correct: true},
            { uri: ''},
        ],
        correct: 'Well done !! You are right !',
        incorrect: 'Oh no, you have a wrong answer please try again.'
    }
];

const Hexagon = styled.img`
  width: 50px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
    //border: solid 1px blue;
    //min-width: 320px !important;
    padding: 0 10px 20px 50px;
    //flex: 1;
`;

const CompletedFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
    //border: solid 1px blue;
    //min-width: 320px !important;
    padding: 20px;
    //flex: 1;
`;

const QuestionLabel = styled.div`
  font-size: 4em;
  font-weight: bolder;
`;

const QuestionText = styled.div`
  padding-left: 6px;
  font-size: 1.4em;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 20px;
  padding: 10px 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
`;

const ImageAnswer = styled.div`
  width: 150px;
  margin-left: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  
  border: 5px solid ${props => props.theme[props.colour]};
`;

const AnswerNote = styled.div`
  border: 2px rgb(205,204, 204) solid;
  width: 100%;
  border-radius: 15px;
  padding: 12px 15px;
  font-size: 1.3em;
  line-height: 25px;
  margin-top: 20px;
  color: white;
  
  ${props => props.correct ? `background-color: ${props.theme.positive}` : `background-color: ${props.theme.primaryThree}`};
`;

const ImageAnswerNote = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 12px 15px;
  font-size: 1.3em;
  line-height: 25px;
  margin-top: 20px;
  
  ${props => props.correct ? `color: ${props.theme.secondaryThree}` : `color: ${props.theme.primaryThree}`};
`;

const PassNote = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 12px 15px;
  font-size: 1.3em;
  line-height: 25px;
  margin-top: 20px;
  color: ${props => props.theme.secondaryThree};
`;

const PassNoteBox = styled.div`
  border: 2px rgb(205,204, 204) solid;
  width: 100%;
  border-radius: 15px;
  padding: 12px 15px;
  font-size: 4em;
  font-weight: 900;
  margin-top: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;  
  height: 170px;
  background-color: ${props => props.theme.positive};
`;

const PassNoteContainer = styled.div`
  margin: 10px 20px;
`;

const QuizzerScreen = (props) => {
    const [quizCount, setQuizCount] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [completed, setCompleted] = useState(false);

    const submit = () => {
        const registerObj = {
            firstName: props.register.firstName,
            lastName: props.register.lastName,
            email: props.register.email,
            address1: props.register.address1,
            address2: props.register.address2,
            address3: props.register.address3,
            address4: props.register.address4,
            postcode: props.register.postcode,
            country: props.register.country,
            dob: props.register.dob,
            webId: props.register.webId,
        };

        props.sendRequest(registerObj);
    };

    const answerColor = (correct, selected, current) => {
        console.log(correct, selected, current);
        if(selected !== current) return 'secondaryThree';
        if(selected === current && correct) return 'positive';
        if(selected === current && !correct) return 'primaryThree';
    };

    const answerButtons = (answers) => {
        const answerButtons = answers.map((answer, idx) => <Button
            style={{
                // width: '80%',
                fontSize: '2.4em',
                fontWeight: 'bold',
                padding: 10,
                border: '2px rgb(205,204, 204) solid',
                textAlign: 'left',
                marginRight: idx === 0 ? '20px' : 0
            }}
            status={answerColor(answer.correct, selectedAnswer, idx)}
            textStatus='light'
            onClick={() => setSelectedAnswer(idx)}
        >{answer.answer}</Button>);

        return answerButtons;
    };

    const imageButtons = (images) => {
        const imageAnswers = images.map((image, idx) => <ImageAnswer onClick={() => setSelectedAnswer(idx)} colour={answerColor(image.correct, selectedAnswer, idx)}>?</ImageAnswer>)

        return imageAnswers;
    };

    const isCorrect = () => {
        return quiz[quizCount].answers && quiz[quizCount].answers.length > 0 ? quiz[quizCount].answers[selectedAnswer].correct : quiz[quizCount].images[selectedAnswer].correct;
    };

    const nextQuestion = () => {
        if(quizCount + 1 <= quiz.length - 1) {
            setQuizCount(quizCount + 1);
        }
        if(quizCount + 1 > quiz.length - 1) {
            setCompleted(true);
        }
        setSelectedAnswer(null);
    };

    return (
        <Background style={{justifyContent: 'space-between'}}>
            {!completed ? <QuestionContainer>
                <QuestionLabel>Q{quizCount + 1}</QuestionLabel>
                <QuestionText>{quiz[quizCount].question}</QuestionText>

                {quiz[quizCount].type === 'answers' ? <>
                    <ButtonsContainer>{answerButtons(quiz[quizCount].answers)}</ButtonsContainer>
                    {selectedAnswer !== null ?
                        <AnswerNote correct={isCorrect()}>
                            <span style={{fontWeight: 'bold'}}>{isCorrect() ? 'Correct' : 'Wrong'}</span><br />
                            {isCorrect() ? quiz[quizCount].correct : quiz[quizCount].incorrect}
                        </AnswerNote> : null}
                </> : null}

                {quiz[quizCount].type === 'images' ? <>
                    <ImagesContainer>{imageButtons(quiz[quizCount].images)}</ImagesContainer>
                    {selectedAnswer !== null ?
                        <ImageAnswerNote correct={isCorrect()}>
                            {isCorrect() ? quiz[quizCount].correct : quiz[quizCount].incorrect}
                        </ImageAnswerNote> : null}
                </> : null}


            </QuestionContainer> : null}

            {completed && !props.register.requestSent ? <PassNoteContainer>
                <PassNote><span style={{fontWeight: 'bold'}}>Congratulations !!</span><br />
                    You have successfully passed the installation installer requirement.<br /><br />
                    Please click submit below to request access to the Intelizzz Installer App.
                </PassNote>
                <PassNoteBox>PASS</PassNoteBox>
            </PassNoteContainer> : null}

            {completed && props.register.requestSent ? <PassNoteContainer>
                <PassNote><span style={{fontWeight: 'bold'}}>Request Sent</span><br />
                    You have successfully sent your request to become an Installer.
                </PassNote>
                <PassNoteBox style={{textAlign: 'center'}}>THANK YOU</PassNoteBox>
                <PassNote>
                    You will receive an email once your request has been approved. You may now close this window.
                </PassNote>
            </PassNoteContainer> : null}

            {completed && !props.register.requestSent ? <CompletedFooter>
                <Button onClick={submit}>
                    {props.register.sendingRequest ? <Spinner colour="white" width={20} height={20} top={'0px'} />
                    : 'SUBMIT'}
                </Button>
            </CompletedFooter> : null}

            {!completed ? <Footer>
                {selectedAnswer !== null && isCorrect() ? <Button
                    status="invisible"
                    style={{
                        // width: 55,
                        // display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        fontSize: '0.8em',
                        fontWeight: 100,
                    }}>
                    Continue
                    <div
                        style={{
                            width: 55,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingLeft: 8,
                        }}>
                        <RiArrowRightLine
                            style={{
                                position: 'absolute',
                                color: 'rgb(255,255,255)'
                            }}
                            size="30"
                            onClick={nextQuestion}
                            color="red"/>
                        <Hexagon src='/assets/images/shapes/hexagonal.svg'/>
                    </div>
                </Button> : null}
            </Footer> : null}
        </Background>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {register: state.register};
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendRequest: (payload) => dispatch(sendRequest(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizzerScreen);
