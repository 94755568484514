const theme = {
    primaryOne: 'linear-gradient(80deg, rgba(218,0,17,1) 81%, rgba(174,0,26,1) 100%)',
    primaryTwo: 'linear-gradient(80deg, rgba(61,60,59,1) 0%, rgba(29,29,28,1) 100%)',
    primaryTwoAlt: 'linear-gradient(90deg, rgba(230,50,42,1) 0%, rgba(191,38,37,1) 80%, rgba(191,23,34,1) 100%)',
    primaryThree: 'rgb(227,11,19)',
    secondaryOne: 'rgb(237, 237, 237)',
    secondaryTwo: 'rgb(243, 146, 0)',
    secondaryThree: 'rgb(52, 51, 51)',
    positive: 'rgb(58, 170, 53)',
    invisible: 'transparent',
    text: {
        dark: 'rgb(29, 29, 27)',
        light: 'white',
        main: 'rgb(60, 60, 59)',
    },
    version: '0.0.2',
    logos: {
        primary: {
            image: '/assets/images/logos/installer.png',
            width: 130,
        },
        zzzlogo: '/assets/images/logos/zzz-logo-black.png',
        dealers: {
            honda: '/assets/images/logos/dealerships/honda.png',
            // phoenix: '/assets/images/logos/dealerships/honda.png',
            ford: '/assets/images/logos/dealerships/ford.png',
        }
    },

};


export default theme;
